var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { useEffect, } from 'react';
import { Button, Checkbox, Icons, Radio, } from '../..';
import { ChoiceTag } from '../ChoiceTag';
import useStyles from './styles/Filter.styles';
export var Filter = function (_a) {
    var locale = _a.locale, getSelectedFilters = _a.getSelectedFilters, state = _a.state, setState = _a.setState;
    var classes = useStyles();
    var selectedFilterIndex = state.findIndex(function (_a) {
        var selected = _a.selected;
        return selected;
    });
    var selectedFilter = state[selectedFilterIndex];
    useEffect(function () {
        if (getSelectedFilters) {
            var selectedFilterTypeAndOption_1 = [];
            state.forEach(function (_a) {
                var options = _a.options, value = _a.value;
                var selectedFilterOptions = options.filter(function (_a) {
                    var selected = _a.selected;
                    return selected;
                });
                if (selectedFilterOptions.length) {
                    selectedFilterTypeAndOption_1.push({ value: value, options: __spreadArray([], __read(selectedFilterOptions.map(function (filterOption) { return filterOption.value; }))) });
                }
            });
            getSelectedFilters(selectedFilterTypeAndOption_1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    var handleOptionClick = function (type, filterIndex, optionIndex) {
        setState(function (prev) {
            var _prev = __spreadArray([], __read(prev));
            _prev[filterIndex] = __assign(__assign({}, _prev[filterIndex]), { options: _prev[filterIndex].options.map(function (_a, i) {
                    var selected = _a.selected, rest = __rest(_a, ["selected"]);
                    return (__assign(__assign({}, rest), { 
                        // eslint-disable-next-line no-nested-ternary
                        selected: i === optionIndex
                            ? (_prev[filterIndex].options[optionIndex].selected && type === 'radio') || !_prev[filterIndex].options[optionIndex].selected
                            : type === 'radio'
                                ? false
                                : _prev[filterIndex].options[i].selected }));
                }) });
            return _prev;
        });
    };
    var handleFilterClick = function (filterIndex) {
        setState(function (prev) { return prev.map(function (rest, i) { return (__assign(__assign({}, rest), { selected: i === filterIndex ? !prev[filterIndex].selected : false })); }); });
    };
    var handleResetFilters = function () {
        setState(state.map(function (_a, filterIndex) {
            var type = _a.type, options = _a.options, rest = __rest(_a, ["type", "options"]);
            return (__assign(__assign({}, rest), { type: type, selected: filterIndex === 0, options: options.map(function (option, optionIndex) { return (__assign(__assign({}, option), { selected: type === 'radio' ? optionIndex === 0 : true })); }) }));
        }));
    };
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx("div", __assign({ className: classes.flexContainer }, { children: state.map(function (_a, filterIndex) {
                    var _b, _c, _d;
                    var label = _a.label, value = _a.value, options = _a.options, type = _a.type, selected = _a.selected;
                    var selectedOptionCount = options.filter(function (option) { return option.selected; }).length;
                    return (_jsxs("div", __assign({ className: classes.flexContainer }, { children: [_jsx(ChoiceTag, __assign({ selected: selected, onClick: function () { return handleFilterClick(filterIndex); } }, { children: _jsxs("div", __assign({ className: classes.filterLabel }, { children: [_jsx("span", __assign({ className: cx((_b = {},
                                                _b[classes.selected] = !!selectedOptionCount && !selected,
                                                _b[classes.active] = selected,
                                                _b)) }, { children: label }), void 0), _jsxs("div", __assign({ className: classes.iconAndCountContainer }, { children: [type === 'checkbox' && (_jsx(_Fragment, { children: _jsx("div", __assign({ className: cx((_c = {},
                                                            _c[classes.selectedOptionCount] = !!selectedOptionCount,
                                                            _c)) }, { children: selectedOptionCount !== 0 && selectedOptionCount }), void 0) }, void 0)), _jsx(Icons.ArrowDropDown, { className: cx(classes.filterIcon, (_d = {},
                                                        _d[classes.selected] = !!selectedOptionCount && !selected,
                                                        _d[classes.active] = selected,
                                                        _d)) }, void 0)] }), void 0)] }), void 0) }), void 0), filterIndex === state.length - 1 && (_jsx(Button, { color: 'ghost', label: locale.resetFilter, variant: 'autoWidth', onClick: handleResetFilters }, void 0))] }), value));
                }) }), void 0), _jsx("div", __assign({ className: classes.flexContainer }, { children: selectedFilter && (selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.options.map(function (option, optionIndex) { return (selectedFilter.type === 'checkbox'
                    ? (_jsx(Checkbox, { onClick: function () { return handleOptionClick(selectedFilter.type, selectedFilterIndex, optionIndex); }, parentContainerClassname: cx(classes.option, classes.customCheckbox), checked: option.selected, label: option.label }, option.value))
                    : (_jsx(Radio, { onClick: function () { return handleOptionClick(selectedFilter.type, selectedFilterIndex, optionIndex); }, parentContainerClassname: cx(classes.option, classes.customRadio), checked: option.selected, label: option.label }, option.value))); })) }), void 0)] }), void 0));
};
