var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TeamFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M10 10.2C10.5 10.2 10.9 10.1 11.3 9.9C11.3 9.9 11.3 9.8 11.4 9.8C11.4 8.2 12.2 6.7 13.5 5.8C13.1 4.2 11.7 3 10 3C8 3 6.4 4.6 6.4 6.6C6.4 8.6 8 10.2 10 10.2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M11.0236 12.0529C11.3231 12.0769 11.6116 12.1 11.9 12.1C11.95 12.2 12.025 12.3 12.1 12.4C12.175 12.5 12.25 12.6 12.3 12.7C11 13.2 10 14.1 9.3 15.1C8.2 16.9 8 18.7 8 19.7C8 20.2 8.1 20.6 8.3 21H3.7C2.8 21 2 20.3 2 19.3C2 18.3 2.2 16.5 3.3 14.9C4.5 13.2 6.5 12 10 12C10.3635 12 10.7 12.027 11.0236 12.0529Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M16 12.6C17.5 12.6 18.7 11.3 18.7 9.8C18.7 8.3 17.5 7 16 7C14.5 7 13.3 8.3 13.3 9.8C13.3 11.3 14.5 12.6 16 12.6Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M11 16.2C11.9 15 13.4 14 16 14C18.6 14 20.1 15 21 16.2C21.8 17.4 22 18.9 22 19.6C22 20.4 21.4 20.9 20.7 20.9H11.3C10.6 21 10 20.5 10 19.7C10 18.9 10.1 17.5 11 16.2Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default TeamFilled;
