var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Checkbox as MUICheckbox, } from '@material-ui/core';
import cx from 'classnames';
import useStyles from './styles/Checkbox.styles';
import { Check, Minus } from '../icons';
import Caption from '../Typography/Caption';
export var Checkbox = forwardRef(function (_a, ref) {
    var rest = __rest(_a, []);
    var styles = useStyles();
    var label = rest.label, className = rest.className, id = rest.id, labelClassName = rest.labelClassName, parentContainerClassname = rest.parentContainerClassname, restProps = __rest(rest, ["label", "className", "id", "labelClassName", "parentContainerClassname"]);
    var selectedId = id || "fallback-checkbox-id-" + (label || '1');
    return (_jsxs("div", __assign({ className: parentContainerClassname }, { children: [_jsx(MUICheckbox, __assign({ id: selectedId, ref: ref, disableRipple: true, color: 'primary', icon: _jsx("span", { className: styles.icon }, void 0), checkedIcon: (_jsx("span", __assign({ className: cx(styles.icon, styles.checkedIcon) }, { children: _jsx(Check, { style: { fontSize: 16 } }, void 0) }), void 0)), indeterminateIcon: (_jsx("span", __assign({ className: cx(styles.icon, styles.indeterminateIcon) }, { children: _jsx(Minus, { style: { fontSize: 16 } }, void 0) }), void 0)), classes: {
                    root: cx(styles.rootStyles, className),
                    checked: styles.checkedStyles,
                    indeterminate: styles.indeterminateStyles,
                } }, restProps), void 0), label && _jsx("label", __assign({ htmlFor: selectedId, className: cx(styles.checkboxLabel, labelClassName) }, { children: _jsx(Caption, __assign({ variant: 'bold' }, { children: label }), void 0) }), void 0)] }), void 0));
});
