var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './styles';
var StaffMenuContracts = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsxs("svg", __assign({ width: '19', height: '20', viewBox: '0 0 19 20', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M0 0.992715C0 0.444454 0.447158 0 0.998756 0H9.98756C10.5392 0 10.9863 0.444454 10.9863 0.992715V12.9053C10.9863 13.4536 10.5392 13.898 9.98756 13.898H0.998756C0.447158 13.898 0 13.4536 0 12.9053V0.992715ZM1.99751 1.98543V11.9126H8.98881V1.98543H1.99751Z', fill: '#7F8897' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.0183 5.20072C13.1624 4.67151 13.7109 4.35864 14.2433 4.50191L18.262 5.58325C18.7934 5.72626 19.1082 6.2698 18.9657 6.79841L15.6053 19.264C15.4625 19.7936 14.9148 20.1078 14.382 19.9659L6.73255 17.9287C6.19975 17.7868 5.88356 17.2424 6.02632 16.7128C6.16909 16.1832 6.71674 15.869 7.24955 16.0109L13.9343 17.7912L16.7784 7.24096L13.7213 6.41835C13.1889 6.27508 12.8741 5.72993 13.0183 5.20072Z', fill: '#7F8897' }, void 0)] }), void 0) }), void 0)); };
export default StaffMenuContracts;
