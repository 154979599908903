var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeBalanceEnd = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_17574_239329)' }, { children: _jsx("path", { d: 'M18.0625 3H5.6875C4.7875 3 4 3.7875 4 4.6875V19.3125C4 20.2125 4.7875 21 5.6875 21H18.0625C18.9625 21 19.75 20.2125 19.75 19.3125V4.6875C19.75 3.7875 18.9625 3 18.0625 3ZM8.5 18.3C8.5 18.525 8.275 18.75 8.05 18.75H6.7C6.475 18.75 6.25 18.525 6.25 18.3V16.95C6.25 16.725 6.475 16.5 6.7 16.5H8.05C8.275 16.5 8.5 16.725 8.5 16.95V18.3ZM8.5 13.8C8.5 14.025 8.275 14.25 8.05 14.25H6.7C6.475 14.25 6.25 14.025 6.25 13.8V12.45C6.25 12.225 6.475 12 6.7 12H8.05C8.275 12 8.5 12.225 8.5 12.45V13.8ZM13 18.3C13 18.525 12.775 18.75 12.55 18.75H11.2C10.975 18.75 10.75 18.525 10.75 18.3V16.95C10.75 16.725 10.975 16.5 11.2 16.5H12.55C12.775 16.5 13 16.725 13 16.95V18.3ZM13 13.8C13 14.025 12.775 14.25 12.55 14.25H11.2C10.975 14.25 10.75 14.025 10.75 13.8V12.45C10.75 12.225 10.975 12 11.2 12H12.55C12.775 12 13 12.225 13 12.45V13.8ZM17.5 18.3C17.5 18.525 17.275 18.75 17.05 18.75H15.7C15.475 18.75 15.25 18.525 15.25 18.3V12.45C15.25 12.225 15.475 12 15.7 12H17.05C17.275 12 17.5 12.225 17.5 12.45V18.3ZM17.5 9.3C17.5 9.525 17.275 9.75 17.05 9.75H6.7C6.475 9.75 6.25 9.525 6.25 9.3V5.7C6.25 5.475 6.475 5.25 6.7 5.25H17.05C17.275 5.25 17.5 5.475 17.5 5.7V9.3Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_17574_239329' }, { children: _jsx("rect", { width: '15.75', height: '18', fill: 'white', transform: 'translate(4 3)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimeBalanceEnd;
