var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Dislike = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9.70809 13.0884C9.5266 13.6329 9.01708 14.0001 8.44318 14.0001H7.59035C6.85397 14.0001 6.25701 13.4032 6.25701 12.6668L6.25701 10.0001H2.66904C1.67786 10.0001 1.0332 8.95706 1.47647 8.07053L4.14314 2.73719C4.36899 2.28548 4.83068 2.00015 5.3357 2.00015L9.84499 2.00015C10.35 2.00015 10.8117 2.28548 11.0376 2.73719L12.0631 4.78835C12.2213 5.10464 12.2473 5.47079 12.1355 5.80627L9.70809 13.0884Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12.7129 9.96593C12.3636 9.8495 12.1748 9.47196 12.2912 9.12266L13.4499 5.6466C13.5058 5.47887 13.4928 5.29579 13.4137 5.13764L12.3274 2.96495C12.1627 2.63564 12.2962 2.23519 12.6255 2.07053C12.9549 1.90587 13.3553 2.03935 13.52 2.36867L14.6063 4.54136C14.8435 5.01579 14.8826 5.56503 14.7148 6.06824L13.5561 9.5443C13.4397 9.89359 13.0622 10.0824 12.7129 9.96593Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Dislike;
