var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Funeral = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_13771_155990)' }, { children: _jsx("path", { d: 'M16.276 8.69977L13.7509 6.21961C13.6077 6.07898 13.4134 6 13.2109 6H10.7889C10.5864 6 10.3921 6.07898 10.2489 6.21961L7.72371 8.69977C7.5341 8.88609 7.45769 9.15633 7.52285 9.41203L9.56448 17.4319C9.64933 17.7659 9.95495 18 10.3053 18H13.6949C14.0453 18 14.3506 17.7659 14.4357 17.4319L16.4774 9.41203C16.542 9.15633 16.4659 8.88609 16.276 8.69977ZM14.2499 10.5C14.2499 10.7072 14.082 10.875 13.8749 10.875H12.5624V13.875C12.5624 14.0822 12.3946 14.25 12.1874 14.25H11.8124C11.6052 14.25 11.4374 14.0822 11.4374 13.875V10.875H10.1249C9.91769 10.875 9.74987 10.7072 9.74987 10.5V10.125C9.74987 9.91781 9.91769 9.75 10.1249 9.75H11.4374V8.625C11.4374 8.41781 11.6052 8.25 11.8124 8.25H12.1874C12.3946 8.25 12.5624 8.41781 12.5624 8.625V9.75H13.8749C14.082 9.75 14.2499 9.91781 14.2499 10.125V10.5Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_13771_155990' }, { children: _jsx("rect", { width: '9', height: '12', fill: 'white', transform: 'translate(7.5 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Funeral;
