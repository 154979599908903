var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Reactions = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M17.6568 6.34314C14.5327 3.21895 9.46734 3.21895 6.34315 6.34315L6.34314 6.34315C3.21895 9.46732 3.21895 14.5326 6.34315 17.6568L5.64692 18.3531L6.34315 17.6568C9.46732 20.781 14.5326 20.781 17.6568 17.6568L18.3639 18.3639L17.6568 17.6568C20.781 14.5327 20.781 9.46734 17.6568 6.34314ZM19.0707 4.92863C15.1655 1.02369 8.83408 1.02379 4.92894 4.92893L19.0707 4.92863ZM19.0707 4.92863C19.0708 4.92873 19.0709 4.92883 19.071 4.92894C22.9763 8.83418 22.9763 15.1658 19.071 19.071C15.1658 22.9763 8.83416 22.9763 4.92893 19.071C1.02369 15.1658 1.02369 8.83416 4.92893 4.92893', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8 14C8 13.4477 8.44772 13 9 13H15C15.5523 13 16 13.4477 16 14C16 16.2193 14.2193 18 12 18C9.78072 18 8 16.2193 8 14ZM10.2628 15C10.6057 15.6004 11.251 16 12 16C12.749 16 13.3943 15.6004 13.7372 15H10.2628Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8.5 8C9.05228 8 9.5 8.44772 9.5 9V10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10V9C7.5 8.44772 7.94772 8 8.5 8Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M15.5 8C16.0523 8 16.5 8.44772 16.5 9V10C16.5 10.5523 16.0523 11 15.5 11C14.9477 11 14.5 10.5523 14.5 10V9C14.5 8.44772 14.9477 8 15.5 8Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Reactions;
