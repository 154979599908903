var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Excel = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M2.99999 0H15.7447L22.5 6.72902V22.5C22.5 23.3287 21.828 24 21 24H2.99999C2.17196 24 1.5 23.3287 1.5 22.5V1.49999C1.5 0.671265 2.17204 0 2.99999 0Z', fill: '#32C356' }, void 0), _jsx("path", { d: 'M22.4783 6.74965H17.25C16.422 6.74965 15.75 6.07769 15.75 5.24966V0.0146484L22.4783 6.74965Z', fill: '#0E956F' }, void 0), _jsx("path", { d: 'M6 9.75V19.5H17.9999V9.75H6ZM15 10.5V11.9999H13.5V10.5H15ZM10.5 14.25H8.99997V12.7507H10.5V14.25ZM11.25 12.7507H12.75V14.25H11.25V12.7507ZM10.5 15.0008V16.5001H8.99997V15.0008H10.5ZM11.25 15.0008H12.75V16.5001H11.25V15.0008ZM13.5 15.0008H15V16.5001H13.5V15.0008ZM13.5 14.25V12.7507H15V14.25H13.5ZM12.75 10.5V11.9999H11.25V10.5H12.75ZM10.5 10.5V11.9999H8.99997V10.5H10.5ZM6.74995 10.5H8.24994V11.9999H6.74995V10.5ZM6.74995 12.7507H8.24994V14.25H6.74995V12.7507ZM6.74995 15.0008H8.24994V16.5001H6.74995V15.0008ZM6.74995 18.7508V17.25H8.24994V18.7508H6.74995ZM8.99997 18.7508V17.25H10.5V18.7508H8.99997ZM11.25 18.7508V17.25H12.75V18.7508H11.25ZM13.5 18.7508V17.25H15V18.7508H13.5ZM17.25 18.7508H15.75V17.25H17.25V18.7508ZM17.25 16.5H15.75V15.0007H17.25V16.5ZM17.25 14.25H15.75V12.7507H17.25V14.25ZM17.25 12H15.75V10.5H17.25V12Z', fill: '#D7F7ED' }, void 0)] }), void 0) }), void 0));
};
export default Excel;
