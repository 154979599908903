var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeSheetsFolder = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M10.1429 10.6816V13.6406C10.1429 13.7695 10.0344 13.875 9.90179 13.875H9.09821C8.96562 13.875 8.85714 13.7695 8.85714 13.6406V10.6816C8.85714 10.5527 8.96562 10.4473 9.09821 10.4473H9.90179C10.0344 10.4473 10.1429 10.5527 10.1429 10.6816Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 4C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V11.5C22 9.84315 20.6569 8.5 19 8.5H17.0704C16.736 8.5 16.4238 8.3329 16.2383 8.0547L14.4258 5.3359C13.8694 4.5013 12.9327 4 11.9296 4H5ZM9.5 17C11.8083 17 13.6786 15.1816 13.6786 12.9375C13.6786 12.1211 13.4315 11.3613 13.0056 10.7285L13.6083 10.1426C13.7027 10.0508 13.7027 9.90234 13.6083 9.81055L13.0397 9.25781C12.9453 9.16602 12.7926 9.16602 12.6982 9.25781L12.1458 9.79492C11.5833 9.3457 10.8962 9.03711 10.1429 8.92383V8.25H10.7054C10.8379 8.25 10.9464 8.14453 10.9464 8.01562V7.23438C10.9464 7.10547 10.8379 7 10.7054 7H8.29464C8.16205 7 8.05357 7.10547 8.05357 7.23438V8.01562C8.05357 8.14453 8.16205 8.25 8.29464 8.25H8.85714V8.92383C6.85424 9.22266 5.32143 10.9062 5.32143 12.9375C5.32143 15.1816 7.19174 17 9.5 17Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default TimeSheetsFolder;
