var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var PieChart = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_54669_345814)' }, { children: _jsx("path", { d: 'M12.375 11.625V6.38906C12.375 6.17813 12.5391 6 12.75 6C15.6492 6 18 8.35078 18 11.25C18 11.4609 17.8219 11.625 17.6109 11.625H12.375ZM6 12.375C6 9.53203 8.11172 7.17891 10.8516 6.80391C11.0672 6.77344 11.25 6.94688 11.25 7.16484V12.75L14.918 16.418C15.075 16.575 15.0633 16.8328 14.8828 16.9594C13.9641 17.6156 12.8391 18 11.625 18C8.51953 18 6 15.4828 6 12.375ZM18.3375 12.75C18.5555 12.75 18.7266 12.9328 18.6984 13.1484C18.518 14.4586 17.8875 15.6234 16.9664 16.4836C16.8258 16.6148 16.6055 16.6055 16.4695 16.4672L12.75 12.75H18.3375Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_54669_345814' }, { children: _jsx("rect", { width: '13.5', height: '12', fill: 'white', transform: 'translate(5.25 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default PieChart;
