var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Violation = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '22', height: '22', viewBox: '0 0 22 22', fill: 'none' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11 7.37891C11.5523 7.37891 12 7.82662 12 8.37891V12.1189C12 12.6712 11.5523 13.1189 11 13.1189C10.4477 13.1189 10 12.6712 10 12.1189V8.37891C10 7.82662 10.4477 7.37891 11 7.37891Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9.75001 15.3786C9.74694 14.683 10.3113 14.125 10.999 14.125C11.6877 14.125 12.25 14.6821 12.25 15.375C12.25 16.0653 11.6903 16.625 11 16.625C10.3109 16.625 9.75197 16.0672 9.75001 15.3786Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12.1618 3.63503C11.6487 2.73624 10.3539 2.73624 9.84067 3.63503L2.18151 17.0393C1.67188 17.9305 2.31501 19.0402 3.34226 19.0402H18.6603C19.686 19.0402 20.3303 17.931 19.821 17.0393C19.821 17.0393 19.821 17.0393 19.821 17.0393L12.1618 3.63503ZM13.8987 2.64335C12.6179 0.400227 9.38493 0.400135 8.104 2.64307C8.10395 2.64316 8.10406 2.64298 8.104 2.64307L0.445187 16.0467C-0.827179 18.2716 0.77951 21.0402 3.34226 21.0402H18.6603C21.2224 21.0402 22.8282 18.2715 21.5576 16.0472L13.8987 2.64335Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Violation;
