var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var AddFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '25', height: '24', viewBox: '0 0 25 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9.40418 2L15.5965 2C19.4031 2 22.5 5.0969 22.5 8.90346V15.0965C22.5 18.9031 19.4031 22 15.5965 22H9.40418C5.59723 22 2.5 18.9031 2.5 15.0965L2.5 8.90346C2.5 5.09685 5.59723 2 9.40418 2ZM12.5008 7.30005C12.8874 7.30005 13.2008 7.61345 13.2008 8.00005L13.2008 11.3H16.5012C16.8878 11.3 17.2012 11.6134 17.2012 12C17.2012 12.3866 16.8878 12.7 16.5012 12.7H13.2008V16C13.2008 16.3866 12.8874 16.7 12.5008 16.7C12.1142 16.7 11.8008 16.3866 11.8008 16V12.7L8.50117 12.7C8.11457 12.7 7.80117 12.3866 7.80117 12C7.80117 11.6134 8.11457 11.3 8.50117 11.3L11.8008 11.3L11.8008 8.00005C11.8008 7.61345 12.1142 7.30005 12.5008 7.30005Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default AddFilled;
