var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var NotificationFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M18.6667 12L19.0595 12.3788C19.5781 12.8789 20 13.8607 20 14.5701V15.8585C20 16.5737 19.4016 17.1429 18.6634 17.1429H5.33663C4.60768 17.1429 4 16.5678 4 15.8585V14.5701C4 13.8548 4.42108 12.8797 4.9405 12.3788L5.33333 12V10.6937C5.33333 7.57586 7.63733 4.99929 10.6933 4.413C10.6893 4.36929 10.6667 4.33071 10.6667 4.28571C10.6667 3.57857 11.2667 3 12 3C12.7333 3 13.3333 3.57857 13.3333 4.28571C13.3333 4.33071 13.3107 4.36929 13.3067 4.413C16.3627 4.99929 18.6667 7.59514 18.6667 10.7143V12ZM14.6667 18.4286C14.6667 19.848 13.4733 21 12 21C10.5267 21 9.33333 19.848 9.33333 18.4286H14.6667Z', fill: '#7F8897' }, void 0) }), void 0) }), void 0));
};
export default NotificationFilled;
