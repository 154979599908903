var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var MoveTo = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5.29289 14.2929C5.68342 13.9024 6.31658 13.9024 6.70711 14.2929L8.70711 16.2929C9.09763 16.6834 9.09763 17.3166 8.70711 17.7071L6.70711 19.7071C6.31658 20.0976 5.68342 20.0976 5.29289 19.7071C4.90237 19.3166 4.90237 18.6834 5.29289 18.2929L6.58579 17L5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 17C2 16.4477 2.44772 16 3 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H3C2.44772 18 2 17.5523 2 17Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 6C2 4.34315 3.34315 3 5 3H9.4705C9.47048 3 9.47051 3 9.4705 3C10.1325 2.99999 10.7517 3.32758 11.1241 3.87494L12.5291 5.94H19C20.6569 5.94 22 7.28315 22 8.94V18C22 19.6569 20.6569 21 19 21H11C10.4477 21 10 20.5523 10 20C10 19.4477 10.4477 19 11 19H19C19.5523 19 20 18.5523 20 18V8.94C20 8.38772 19.5523 7.94 19 7.94H12.5291C11.8671 7.94 11.248 7.61241 10.8756 7.06506L9.47058 5H5C4.44772 5 4 5.44772 4 6V12C4 12.5523 3.55228 13 3 13C2.44772 13 2 12.5523 2 12V6Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default MoveTo;
