var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var History = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M4 6C4 5.44772 4.44772 5 5 5H12C16.4523 5 20 8.54772 20 13C20 17.4523 16.4523 21 12 21C7.54772 21 4 17.4523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13C6 16.3477 8.65228 19 12 19C15.3477 19 18 16.3477 18 13C18 9.65228 15.3477 7 12 7H5C4.44772 7 4 6.55228 4 6Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711L6.41421 6L8.70711 8.29289C9.09763 8.68342 9.09763 9.31658 8.70711 9.70711C8.31658 10.0976 7.68342 10.0976 7.29289 9.70711L4.29289 6.70711C3.90237 6.31658 3.90237 5.68342 4.29289 5.29289L7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M12 9C11.4477 9 11 9.44772 11 10V14C11 14.5523 11.4477 15 12 15H15C15.5523 15 16 14.5523 16 14C16 13.4477 15.5523 13 15 13H13V10C13 9.44772 12.5523 9 12 9Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default History;
