var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Read = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10.0782 4.88272C10.3653 4.59508 10.8309 4.59508 11.118 4.88272C11.383 5.14823 11.4034 5.56601 11.1792 5.85494L11.118 5.92433L5.93358 11.1179C5.66854 11.3834 5.25149 11.4039 4.96307 11.1792L4.89381 11.1179L2.21534 8.43472C1.92822 8.14709 1.92822 7.68074 2.21534 7.39311C2.48038 7.1276 2.89743 7.10718 3.18585 7.33184L3.25511 7.39311L5.41367 9.55535L10.0782 4.88272Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M14.4714 4.86225C14.7318 5.1226 14.7318 5.54471 14.4714 5.80506L9.13807 11.1384C8.87772 11.3987 8.45561 11.3987 8.19526 11.1384C7.93491 10.878 7.93491 10.4559 8.19526 10.1956L13.5286 4.86225C13.7889 4.6019 14.2111 4.6019 14.4714 4.86225Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Read;
