var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Messaging = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M12 2C6.477 2 2 6.477 2 12C2 13.272 2.26199 14.561 2.71899 15.719L3.5 17.312C3.995 18.341 3.82101 19.311 3.15601 20.5C2.78901 21.158 3.247 21.951 4 21.969C5.017 21.992 6.644 21.574 7.938 21.094C9.131 21.645 10.668 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM8 10C8 9.448 8.4477 9 9 9C9.5523 9 10 9.448 10 10V11C10 11.552 9.5523 12 9 12C8.4477 12 8 11.552 8 11V10ZM14 10C14 9.448 14.4477 9 15 9C15.5523 9 16 9.448 16 10V11C16 11.552 15.5523 12 15 12C14.4477 12 14 11.552 14 11V10ZM8.4688 14.125C8.6639 13.93 8.9316 13.844 9.1875 13.844C9.4434 13.844 9.6796 13.93 9.875 14.125C10.4345 14.684 11.1931 15 12 15C12.8041 15 13.5659 14.681 14.125 14.125C14.5167 13.736 15.1419 13.733 15.5312 14.125C15.9206 14.517 15.923 15.142 15.5312 15.531C14.6012 16.456 13.3378 17 12 17C10.6576 17 9.3996 16.461 8.4688 15.531C8.078 15.141 8.0784 14.516 8.4688 14.125Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Messaging;
