var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var EventGroup = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7C6.44772 8 6 7.55228 6 7Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 5.15625C2 3.49897 3.34272 2.15625 5 2.15625H19C20.6573 2.15625 22 3.49897 22 5.15625V19.0002C22 20.6575 20.6573 22.0002 19 22.0002H5C3.34272 22.0002 2 20.6575 2 19.0002V5.15625ZM5 4.15625C4.44728 4.15625 4 4.60353 4 5.15625V19.0002C4 19.553 4.44728 20.0002 5 20.0002H19C19.5527 20.0002 20 19.553 20 19.0002V5.15625C20 4.60353 19.5527 4.15625 19 4.15625H5Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M12.0012 16.1638L13.5052 16.9538C13.7982 17.1078 14.1402 16.8588 14.0842 16.5328L13.7972 14.8577L15.0142 13.6727C15.2512 13.4417 15.1202 13.0398 14.7932 12.9918L13.1112 12.7478L12.3592 11.2228C12.2132 10.9258 11.7892 10.9258 11.6432 11.2228L10.8912 12.7478L9.20917 12.9928C8.88217 13.0398 8.75117 13.4427 8.98817 13.6737L10.2052 14.8588L9.91817 16.5338C9.86217 16.8598 10.2042 17.1088 10.4972 16.9548L12.0012 16.1638', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0)] }), void 0) }), void 0));
};
export default EventGroup;
