var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Flag = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7 2C5.34315 2 4 3.34315 4 5V21C4 21.5523 4.44772 22 5 22C5.55228 22 6 21.5523 6 21V13.8293C6.31278 13.9398 6.64936 14 7 14H17.9194C19.5964 14 20.5287 12.0601 19.4811 10.7506L17.2806 8L19.4811 5.24939C20.5287 3.93986 19.5964 2 17.9194 2H7ZM6 11C6 11.5523 6.44772 12 7 12H17.9194L15.7189 9.24939C15.1345 8.51895 15.1345 7.48105 15.7189 6.75061L17.9194 4H7C6.44772 4 6 4.44772 6 5V11Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Flag;
