var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var UserGroup = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M7.8 11.2016C8.46188 11.2016 9 10.6634 9 10.0016C9 9.33969 8.46188 8.80156 7.8 8.80156C7.13813 8.80156 6.6 9.33969 6.6 10.0016C6.6 10.6634 7.13813 11.2016 7.8 11.2016ZM16.2 11.2016C16.8619 11.2016 17.4 10.6634 17.4 10.0016C17.4 9.33969 16.8619 8.80156 16.2 8.80156C15.5381 8.80156 15 9.33969 15 10.0016C15 10.6634 15.5381 11.2016 16.2 11.2016ZM16.8 11.8016H15.6C15.27 11.8016 14.9719 11.9347 14.7544 12.1503C15.51 12.5647 16.0463 13.3128 16.1625 14.2016H17.4C17.7319 14.2016 18 13.9334 18 13.6016V13.0016C18 12.3397 17.4619 11.8016 16.8 11.8016ZM12 11.8016C13.1606 11.8016 14.1 10.8622 14.1 9.70156C14.1 8.54094 13.1606 7.60156 12 7.60156C10.8394 7.60156 9.9 8.54094 9.9 9.70156C9.9 10.8622 10.8394 11.8016 12 11.8016ZM13.44 12.4016H13.2844C12.8944 12.5891 12.4613 12.7016 12 12.7016C11.5388 12.7016 11.1075 12.5891 10.7156 12.4016H10.56C9.3675 12.4016 8.4 13.3691 8.4 14.5616V15.1016C8.4 15.5984 8.80313 16.0016 9.3 16.0016H14.7C15.1969 16.0016 15.6 15.5984 15.6 15.1016V14.5616C15.6 13.3691 14.6325 12.4016 13.44 12.4016ZM9.24563 12.1503C9.02813 11.9347 8.73 11.8016 8.4 11.8016H7.2C6.53813 11.8016 6 12.3397 6 13.0016V13.6016C6 13.9334 6.26812 14.2016 6.6 14.2016H7.83563C7.95375 13.3128 8.49 12.5647 9.24563 12.1503Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default UserGroup;
