var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Presentation = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M17.625 6.75H6.375C6.168 6.75 6 6.91725 6 7.125V7.875C6 8.082 6.168 8.25 6.375 8.25H6.75V14.25C6.75 14.664 7.08675 15 7.5 15H11.625V15.924L10.6673 16.563C10.4948 16.6777 10.4483 16.911 10.563 17.0828C10.677 17.2545 10.9088 17.301 11.0827 17.187L12 16.5758L12.9172 17.187C12.981 17.2298 13.053 17.25 13.125 17.25C13.2457 17.25 13.3643 17.1915 13.437 17.0828C13.5518 16.911 13.5053 16.6777 13.3328 16.563L12.375 15.924V15H16.5C16.914 15 17.25 14.664 17.25 14.25V8.25H17.625C17.832 8.25 18 8.082 18 7.875V7.125C18 6.91725 17.832 6.75 17.625 6.75ZM9.75 13.5H8.25V12H9.75V13.5ZM12.75 13.5H11.25V10.5H12.75V13.5ZM15.75 13.5H14.25V9.75H15.75V13.5Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Presentation;
