var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Swap = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9.30711 2.69328C9.69763 3.08381 9.69763 3.71697 9.30711 4.1075L6.41421 7.00039L9.30711 9.89328C9.69763 10.2838 9.69763 10.917 9.30711 11.3075C8.91658 11.698 8.28342 11.698 7.89289 11.3075L4.29289 7.7075C4.10536 7.51996 4 7.26561 4 7.00039C4 6.73517 4.10536 6.48082 4.29289 6.29328L7.89289 2.69328C8.28342 2.30276 8.91658 2.30276 9.30711 2.69328Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M14.6933 12.6933C15.0838 12.3028 15.717 12.3028 16.1075 12.6933L19.7075 16.2933C20.098 16.6838 20.098 17.317 19.7075 17.7075L16.1075 21.3075C15.717 21.698 15.0838 21.698 14.6933 21.3075C14.3028 20.917 14.3028 20.2838 14.6933 19.8933L17.5862 17.0004L14.6933 14.1075C14.3028 13.717 14.3028 13.0838 14.6933 12.6933Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Swap;
