var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Link = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3 6C3 4.34315 4.34315 3 6 3H7C7.55228 3 8 3.44772 8 4C8 4.55228 7.55228 5 7 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V17C19 16.4477 19.4477 16 20 16C20.5523 16 21 16.4477 21 17V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.0002 5C12.4479 5 12.0002 4.55228 12.0002 4C12.0002 3.44772 12.4479 3 13.0002 3H20.0712C20.6235 3 21.0712 3.44772 21.0712 4L21.0712 11.0711C21.0712 11.6234 20.6235 12.0711 20.0712 12.0711C19.5189 12.0711 19.0712 11.6234 19.0712 11.0711L19.0712 6.34315L11.7071 13.7073C11.3166 14.0978 10.6834 14.0978 10.2929 13.7073C9.90237 13.3167 9.90237 12.6836 10.2929 12.293L17.5859 5H13.0002Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Link;
