var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var File = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '40', height: '40', viewBox: '0 0 40 40', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M8.40887 12.4335L19.7092 7.09838H16.5165L11.6855 2.98155C11.4755 2.77153 11.1815 2.6875 10.8873 2.6875H2.94772C2.23354 2.6875 1.6875 3.23362 1.6875 3.94772V12.4335H8.40887Z', fill: '#206AE9' }, void 0), _jsx("path", { d: 'M34.8739 12.4327H38.3187V8.35787C38.3187 7.6437 37.7725 7.09766 37.0584 7.09766H32.3535L34.8739 12.4327Z', fill: '#206AE9' }, void 0), _jsx("path", { d: 'M30.7991 3.27544C30.463 2.56126 29.5808 2.22524 28.8247 2.60332L7.48438 12.6854H35.2099L30.7991 3.27544Z', fill: '#ECF6FF' }, void 0), _jsx("path", { d: 'M39.664 12.6017C39.4119 12.3496 39.0759 12.2236 38.7398 12.2236H38.3197H34.875H8.40966H1.68829H1.26825C0.932146 12.2236 0.596126 12.3496 0.34405 12.6017C0.0919749 12.8538 -0.0340227 13.1898 0.00794973 13.5259L1.26817 36.3785C1.31014 37.0506 1.85626 37.5548 2.52838 37.5548H37.4795C38.1516 37.5548 38.6977 37.0507 38.7397 36.3785L40 13.5259C40 13.1898 39.874 12.8537 39.664 12.6017Z', fill: '#5E99FF' }, void 0)] }), void 0) }), void 0));
};
export default File;
