var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/HorizontalStepper.styles';
import { Body } from '../Typography';
export var HorizontalStepper = function (_a) {
    var _b = _a.initialValue, initialValue = _b === void 0 ? 0 : _b, _c = _a.minLimit, minLimit = _c === void 0 ? 0 : _c, _d = _a.maxLimit, maxLimit = _d === void 0 ? 5 : _d, _e = _a.value, value = _e === void 0 ? 0 : _e, _f = _a.onChange, onChange = _f === void 0 ? function () { } : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, _h = _a.enterManually, enterManually = _h === void 0 ? false : _h, _j = _a.className, className = _j === void 0 ? '' : _j, props = __rest(_a, ["initialValue", "minLimit", "maxLimit", "value", "onChange", "disabled", "enterManually", "className"]);
    var classes = useStyles();
    var onChangeHandler = function (e) {
        var newVal = +e.target.value;
        if (isNaN(newVal) || newVal > maxLimit || newVal < minLimit || !Number.isInteger(newVal)) {
            return;
        }
        onChange(newVal);
    };
    var increment = function (event) {
        event.preventDefault();
        onChange(value + 1);
    };
    var decrement = function (event) {
        event.preventDefault();
        onChange(value - 1);
    };
    var incrementDisabled = disabled || value >= maxLimit;
    var decrementDisabled = disabled || value <= minLimit;
    return (_jsxs("div", __assign({}, props, { className: cx(classes.container, className) }, { children: [_jsx("button", __assign({ type: 'button', "aria-label": 'decrement-button', disabled: decrementDisabled, className: classes.button, onClick: decrement }, { children: "-" }), void 0), enterManually ? (_jsx("input", { "aria-label": 'stepper-input', value: value, onChange: onChangeHandler, className: classes.input, disabled: disabled }, void 0)) : (_jsx(Body, __assign({ "aria-label": 'stepper-count-text', className: classes.countText, variant: 'medium' }, { children: value }), void 0)), _jsx("button", __assign({ type: 'button', "aria-label": 'increment-button', disabled: incrementDisabled, className: classes.button, onClick: increment }, { children: "+" }), void 0)] }), void 0));
};
