var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState, } from 'react';
import { InputBase } from '@material-ui/core';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import DatePickerComponent from 'react-datepicker';
import cx from 'classnames';
import { dateTypeEnum, getMonthNames, JobDoneLocales, } from '../../utils';
import { CalendarFilled } from '../icons';
import { Dropdown } from '../Input';
import { FormControl } from '../..';
import { useStyles as useTimePickerStyles } from './styles/TimePicker.styles';
import { useStyles, useInputEndAndormentStyles } from './styles/Datepicker.styles';
import useInputStyles from '../Input/styles/Input.styles';
export var DatePicker = function (_a) {
    var _b, _c;
    var className = _a.className, inputClassName = _a.inputClassName, value = _a.value, _d = _a.inputLocale, inputLocale = _d === void 0 ? {
        errorMessage: '',
        label: 'DatePicker',
        placeholder: 'Choose a date...',
    } : _d, _e = _a.locale, locale = _e === void 0 ? JobDoneLocales.ENGLISH : _e, _f = _a.hasError, hasError = _f === void 0 ? false : _f, _g = _a.dateFormat, dateFormat = _g === void 0 ? 'dd.MM.yyyy' : _g, _h = _a.onChange, onChange = _h === void 0 ? console.log : _h, hiddenLabel = _a.hiddenLabel, required = _a.required, id = _a.id, _j = _a.yearOptionStart, yearOptionStart = _j === void 0 ? 1900 : _j, onFocus = _a.onFocus, onBlur = _a.onBlur, props = __rest(_a, ["className", "inputClassName", "value", "inputLocale", "locale", "hasError", "dateFormat", "onChange", "hiddenLabel", "required", "id", "yearOptionStart", "onFocus", "onBlur"]);
    var timePickerClasses = useTimePickerStyles();
    var classes = useStyles({ step: dateTypeEnum.DATE });
    var inputClasses = useInputStyles();
    var _k = __read(useState(false), 2), isActive = _k[0], setIsActive = _k[1];
    var ref = useRef(null);
    var years = useMemo(function () { return range(yearOptionStart, getYear(new Date()) + 5, 1).reverse().map(function (year) { return ({
        value: year,
        label: year,
    }); }); }, [yearOptionStart]);
    var months = useMemo(function () { return getMonthNames(locale).map(function (month) { return ({
        label: month,
        value: month,
    }); }); }, [locale]);
    var handleFocus = function () {
        onFocus && onFocus();
        setIsActive(true);
    };
    var handeBlur = function () {
        onBlur && onBlur();
        setIsActive(false);
    };
    return (_jsx("div", __assign({ className: cx(timePickerClasses.container, className) }, { children: _jsx(FormControl, __assign({ active: isActive, hiddenLabel: hiddenLabel, error: hasError, errorMessage: inputLocale === null || inputLocale === void 0 ? void 0 : inputLocale.errorMessage, id: id, required: required, label: (inputLocale === null || inputLocale === void 0 ? void 0 : inputLocale.label) || '' }, { children: _jsx(DatePickerComponent, __assign({}, props, { renderCustomHeader: function (_a) {
                    var componentDate = _a.date, changeYear = _a.changeYear, changeMonth = _a.changeMonth;
                    return (_jsxs("div", __assign({ className: classes.calendarHeader }, { children: [_jsx(Dropdown, { options: years, hiddenLabel: true, value: { value: getYear(componentDate), label: getYear(componentDate) }, onChange: function (selectedOption) { return changeYear(parseInt(selectedOption.value, 10)); } }, void 0), _jsx(Dropdown, { options: months, hiddenLabel: true, value: months[getMonth(componentDate)], onChange: function (selectedOption) { return changeMonth(months.indexOf(selectedOption)); } }, void 0)] }), void 0));
                }, selected: value, onChange: onChange, onFocus: handleFocus, onBlur: handeBlur, onCalendarClose: function () { return setIsActive(false); }, customInput: (_jsx(InputBase, { inputRef: ref, "aria-label": 'time-picker-input', className: cx(inputClasses.input, className, (_b = {}, _b[inputClasses.inputError] = hasError, _b)), placeholder: inputLocale.placeholder, style: { fontWeight: 500 }, id: id, onKeyUp: function (e) {
                        var _a;
                        if (e.key === 'Enter') {
                            (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.blur();
                        }
                    }, endAdornment: (_jsx(CalendarFilled, { className: cx(useInputEndAndormentStyles().calendarEndAdornment, (_c = {},
                            _c[useInputEndAndormentStyles().calendarEndAdornmentActive] = isActive,
                            _c)) }, void 0)) }, void 0)), showPopperArrow: false, dateFormat: dateFormat, calendarClassName: classes.calendarRoot, calendarStartDay: 1 }), void 0) }), void 0) }), void 0));
};
