/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import {
  ApolloClient, InMemoryCache, NormalizedCacheObject, from, ApolloLink, split,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import Router from 'next/router';
import { catchErrors, interceptRequest, interceptResponse } from './interceptors';
import getAccessToken from './awsGetAccessToken';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

// ? Props are dynamic and different based on the type of props passed in, so any???
interface PageProps {
  [APOLLO_STATE_PROP_NAME]: any
  props: {
    [APOLLO_STATE_PROP_NAME]: any
  }
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_APOLLO_URI,
  credentials: 'same-origin',
});

const createNewSubscriptionClient = () => {
  const url = process.env.NEXT_PUBLIC_APOLLO_WS_URI || '';
  return createClient({
    url,
    lazy: true,
    connectionParams: async () => {
      const token = await getAccessToken();
      const sessionId = Router.query?.sessionId;
      const [organizationId, organizationUserId] = (sessionId as string)?.split('.') || [];
      return {
        headers: {
          Authorization: `Bearer ${String(token)}`,
          Organization: organizationId,
          ...(organizationUserId ? { 'organization-user-id': organizationUserId } : {}),
        },
      };
    },
  });
};

const wsLink = (typeof window !== 'undefined') ? new GraphQLWsLink(createNewSubscriptionClient()) : null;

const splitLink = typeof window !== 'undefined' ? split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink as unknown as ApolloLink,
  uploadLink as unknown as ApolloLink,
) : uploadLink;

export function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // https://github.com/jaydenseric/apollo-upload-client/issues/213
    link: from([catchErrors, interceptRequest, interceptResponse, splitLink] as unknown as ApolloLink[]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

export function initializeApollo(initialState: Partial<{ [x: string]: any; __META: { extraRootIds: string[]; }; }>) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client: { cache: { extract: () => any; }; }, pageProps: PageProps) {
  let props;
  if (pageProps?.props) {
    props = pageProps.props[APOLLO_STATE_PROP_NAME];
    props = client.cache.extract();
  }

  return props;
}

export function useApollo(pageProps: PageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
