var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var NumberedList = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10 7C10 6.44772 10.4477 6 11 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H11C10.4477 8 10 7.55228 10 7Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10.1484 12C10.1484 11.4477 10.5962 11 11.1484 11H19.9984C20.5507 11 20.9984 11.4477 20.9984 12C20.9984 12.5523 20.5507 13 19.9984 13H11.1484C10.5962 13 10.1484 12.5523 10.1484 12Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5.63268 3.07615C6.00636 3.23093 6.25 3.59557 6.25 4.00003V8.00003H6.5C7.05228 8.00003 7.5 8.44774 7.5 9.00003C7.5 9.55231 7.05228 10 6.5 10H4C3.44772 10 3 9.55231 3 9.00003C3 8.44774 3.44772 8.00003 4 8.00003H4.25V6.21844C3.91872 6.30363 3.55228 6.21653 3.29289 5.95714C2.90237 5.56661 2.90237 4.93345 3.29289 4.54292L4.54289 3.29292C4.82889 3.00692 5.25901 2.92137 5.63268 3.07615Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10 17C10 16.4477 10.4477 16 11 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H11C10.4477 18 10 17.5523 10 17Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3 15C3 14.4477 3.44772 14 4 14H6.5C7.05228 14 7.5 14.4477 7.5 15V17C7.5 17.3513 7.3157 17.6768 7.0145 17.8575L5.11032 19H6.75C7.30228 19 7.75 19.4477 7.75 20C7.75 20.5523 7.30228 21 6.75 21H4C3.44772 21 3 20.5523 3 20V18.5C3 18.1487 3.1843 17.8232 3.4855 17.6425L5.5 16.4338V16H4C3.44772 16 3 15.5523 3 15Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default NumberedList;
