var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { getSafePercent } from './utils';
import useStyles from './styles/ProgressBar.styles';
export var ProgressBar = function (_a) {
    var _b = _a.from, from = _b === void 0 ? 0 : _b, _c = _a.to, to = _c === void 0 ? 100 : _c, _d = _a.delay, delay = _d === void 0 ? 100 : _d, unfilledBgColor = _a.unfilledBgColor, filledBgColor = _a.filledBgColor, parentClassName = _a.parentClassName, childClassName = _a.childClassName, height = _a.height, children = _a.children, props = __rest(_a, ["from", "to", "delay", "unfilledBgColor", "filledBgColor", "parentClassName", "childClassName", "height", "children"]);
    var safePercentOfFrom = getSafePercent(from);
    var safePercentOfTo = getSafePercent(to);
    var _e = __read(useState(safePercentOfFrom), 2), percent = _e[0], setPercent = _e[1];
    useEffect(function () {
        var timeoutId = setTimeout(function () { return setPercent(safePercentOfTo); }, delay);
        return function () { return clearTimeout(timeoutId); };
    }, [safePercentOfFrom, safePercentOfTo, delay]);
    var styles = useStyles();
    var parentClasses = cx(styles.track, parentClassName);
    var childClasses = cx(styles.progress, childClassName);
    return (_jsx("div", __assign({ "data-testid": 'track', className: parentClasses, style: { background: unfilledBgColor, height: height } }, props, { children: _jsx("div", __assign({ "data-testid": 'progress', className: childClasses, style: { width: percent + "%", background: filledBgColor, height: height } }, { children: _jsx("div", __assign({ className: styles.content }, { children: children }), void 0) }), void 0) }), void 0));
};
