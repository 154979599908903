var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Bold = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5.75 5C5.75 4.44772 6.19772 4 6.75 4H12.583C15.0683 4 17.083 6.01472 17.083 8.5C17.083 9.67401 16.6334 10.743 15.897 11.5442C17.2987 12.3066 18.25 13.7922 18.25 15.5C18.25 17.9853 16.2353 20 13.75 20H6.75C6.19772 20 5.75 19.5523 5.75 19V5ZM7.75 13V18H13.75C15.1307 18 16.25 16.8807 16.25 15.5C16.25 14.1193 15.1307 13 13.75 13H7.75ZM12.583 11H7.75V6H12.583C13.9637 6 15.083 7.11928 15.083 8.5C15.083 9.88072 13.9637 11 12.583 11Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Bold;
