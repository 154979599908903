var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ShevronRight = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12.4144 6.82842C12.805 6.43789 13.4381 6.43789 13.8287 6.82842C14.2192 7.21894 14.2192 7.85211 13.8287 8.24263L10.2931 11.7782L13.8287 15.3137C14.2192 15.7042 14.2192 16.3374 13.8287 16.7279C13.4381 17.1184 12.805 17.1184 12.4144 16.7279L8.1718 12.4853C7.78128 12.0947 7.78128 11.4616 8.1718 11.0711L12.4144 6.82842Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default ShevronRight;
