var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Mobile = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8 4C7.44772 4 7 4.44772 7 5L7 19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19L17 5C17 4.44772 16.5523 4 16 4H15C15 5.10457 14.1046 6 13 6H11C9.89543 6 9 5.10457 9 4H8ZM5 5C5 3.34315 6.34315 2 8 2L16 2C17.6569 2 19 3.34315 19 5L19 19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19L5 5Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Mobile;
