var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Drive = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 17C2 14.7477 3.74772 13 6 13H18C20.2523 13 22 14.7477 22 17C22 19.2523 20.2523 21 18 21H6C3.74772 21 2 19.2523 2 17ZM10 17C10 16.4477 10.4477 16 11 16H18C18.5523 16 19 16.4477 19 17C19 17.5523 18.5523 18 18 18H11C10.4477 18 10 17.5523 10 17ZM7.99999 17C7.99999 17.5523 7.55228 18 7 18C6.44771 18 6 17.5523 6 17C6 16.4477 6.44771 16 7 16C7.55228 16 7.99999 16.4477 7.99999 17Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2.90502 5.7005C3.05697 4.18098 4.3947 3 5.90005 3H18.1001C19.7367 3 20.9463 4.21239 21.0951 5.7005L21.0967 5.71845L21.9967 16.7185C22.0418 17.2689 21.632 17.7516 21.0816 17.7967C20.5312 17.8417 20.0484 17.432 20.0034 16.8815L19.1042 5.89193C19.05 5.3838 18.6606 5 18.1001 5H5.90005C5.40789 5 4.94778 5.41482 4.89585 5.89227L3.99672 16.8815C3.95169 17.432 3.46895 17.8417 2.91851 17.7967C2.36806 17.7516 1.95835 17.2689 2.00338 16.7185L2.90338 5.71845L2.90502 5.7005Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Drive;
