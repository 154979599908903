var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import cx from 'classnames';
import { Icons } from '../..';
import useStyles from './styles/Breadcrumb.styles';
export var Breadcrumb = function (_a) {
    var _b = _a.elements, elements = _b === void 0 ? [] : _b, _c = _a.onClick, onClick = _c === void 0 ? function () { } : _c;
    var classes = useStyles();
    return (_jsx(Breadcrumbs, __assign({ separator: _jsx(Icons.ShevronRight, {}, void 0) }, { children: elements.map(function (element, i) {
            var _a;
            return (_jsx("button", __assign({ onClick: function () { return onClick(element.value); }, 
                // disabled={i === elements.length - 1}
                className: cx(classes.element, (_a = {},
                    _a[classes.elementActive] = i === elements.length - 1,
                    _a[classes.firstElement] = i === 0,
                    _a)) }, { children: element.label }), element.value));
        }) }), void 0));
};
