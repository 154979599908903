var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './styles';
var MenuHolidays = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M9 8C7.33333 8.66667 3.6 10.7 2 13.5M11.5 11C9.16667 12.3333 4 16.4 2 22M12.5 14.5C11.3333 15.6667 8.7 18.6 7.5 21', stroke: '#7F8897', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0), _jsx("path", { d: 'M14.6742 6.44945L15.3467 3.16344L17.572 5.67299L20.5992 5.37949L18.8314 8.44136L20.5738 11.0803L17.2878 10.4079L14.7783 12.6332L14.9677 9.47663L11.9059 7.70887L14.6742 6.44945Z', stroke: '#7F8897', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }, void 0)] }), void 0) }), void 0)); };
export default MenuHolidays;
