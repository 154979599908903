export var ShiftEntryTypes = {
    isBlocked: 'isBlocked',
    isLinked: 'isLinked',
    hasConflicts: 'hasConflicts',
    isCopied: 'isCopied',
    basic: 'basic',
};
export var AbsenceEntryTypes = {
    hasConflicts: 'hasConflicts',
    isCopied: 'isCopied',
    basic: 'basic',
    isBlocked: 'isBlocked',
};
