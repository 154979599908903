var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Vacation = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_13771_168929)' }, { children: _jsx("path", { d: 'M16.6666 10.6667H13.8887L11.3342 6.1959C11.3001 6.13638 11.251 6.08691 11.1916 6.05251C11.1323 6.01811 11.0649 6 10.9964 6L9.40435 6C9.14598 6 8.95955 6.24719 9.03053 6.49559L10.2222 10.6667H7.72216L6.67216 9.26667C6.59876 9.16872 6.4833 9.11111 6.36105 9.11111H5.38907C5.13605 9.11111 4.95035 9.34882 5.01185 9.59431L5.77771 12.2222L5.01185 14.8501C4.95035 15.0956 5.13605 15.3333 5.38907 15.3333H6.36105C6.48355 15.3333 6.59876 15.2757 6.67216 15.1778L7.72216 13.7778H10.2222L9.03053 17.9486C8.95955 18.197 9.14598 18.4444 9.40435 18.4444H10.9964C11.1359 18.4444 11.2647 18.3696 11.334 18.2485L13.8887 13.7778H16.6666C17.5258 13.7778 18.9999 13.0814 18.9999 12.2222C18.9999 11.363 17.5258 10.6667 16.6666 10.6667Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_13771_168929' }, { children: _jsx("rect", { width: '14', height: '12.4444', fill: 'white', transform: 'translate(5 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Vacation;
