var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Violated = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M19.0711 19.0706C15.1658 22.9759 8.83417 22.9759 4.92893 19.0706C1.02369 15.1654 1.02369 8.83373 4.92893 4.92849C8.83418 1.02325 15.1658 1.02325 19.0711 4.92849C22.9763 8.83373 22.9763 15.1654 19.0711 19.0706ZM14.1213 8.46402C14.5118 8.0735 15.145 8.0735 15.5355 8.46402C15.9261 8.85455 15.9261 9.48771 15.5355 9.87824L13.4142 11.9996L15.5355 14.1209C15.9261 14.5114 15.9261 15.1446 15.5355 15.5351C15.145 15.9256 14.5118 15.9256 14.1213 15.5351L12 13.4138L9.87868 15.5351C9.48816 15.9256 8.85499 15.9256 8.46447 15.5351C8.07394 15.1446 8.07394 14.5114 8.46447 14.1209L10.5858 11.9996L8.46447 9.87824C8.07394 9.48771 8.07394 8.85455 8.46447 8.46402C8.85499 8.0735 9.48816 8.0735 9.87868 8.46402L12 10.5853L14.1213 8.46402Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Violated;
