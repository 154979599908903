var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Italic = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8.81612 6C8.81612 5.44772 9.26384 5 9.81612 5H18.5431C19.0954 5 19.5431 5.44772 19.5431 6C19.5431 6.55228 19.0954 7 18.5431 7H15.2232L10.9482 17H14.1801C14.7324 17 15.1801 17.4477 15.1801 18C15.1801 18.5523 14.7324 19 14.1801 19H5.45312C4.90084 19 4.45312 18.5523 4.45312 18C4.45312 17.4477 4.90084 17 5.45312 17H8.77308L13.0481 7H9.81612C9.26384 7 8.81612 6.55228 8.81612 6Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Italic;
