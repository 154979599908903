var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeBalanceTarget = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_17574_239323)' }, { children: _jsx("path", { d: 'M11.5135 11.4611L3.98376 13.6014C3.45044 13.7529 3.38786 14.4838 3.88778 14.7236L6.29493 15.8798L3.16005 19.0147C2.94665 19.2278 2.94665 19.5737 3.16005 19.7868L3.93208 20.5588C4.14548 20.7718 4.49106 20.7718 4.70411 20.5588L7.83899 17.4239L8.99528 19.8311C9.2354 20.331 9.96595 20.2684 10.1175 19.7351L12.2574 12.2053C12.3868 11.7514 11.9674 11.3324 11.5135 11.4611ZM12.9518 13.8784L12.2209 16.4494C14.4666 16.1966 16.2188 14.3133 16.2188 12C16.2188 9.5148 14.204 7.5 11.7188 7.5C9.40556 7.5 7.52188 9.25219 7.26946 11.498L9.84044 10.7674C10.243 10.1557 10.9334 9.75 11.7188 9.75C12.9595 9.75 13.9688 10.7593 13.9688 12C13.9688 12.7854 13.5631 13.4759 12.9518 13.8784ZM11.7188 3.28125C6.90349 3.28125 3.00009 7.18465 3.00009 12C3.00009 12.295 3.01556 12.5861 3.04438 12.8736C3.2279 12.7193 3.43286 12.5885 3.67649 12.5193L5.25395 12.071C5.2536 12.0471 5.25044 12.0243 5.25044 12.0004C5.25044 8.42531 8.14345 5.5316 11.7192 5.5316C15.2942 5.5316 18.1879 8.42461 18.1879 12.0004C18.1879 15.5754 15.2949 18.4691 11.7192 18.4691C11.6953 18.4691 11.6721 18.4659 11.6485 18.4656L11.2003 20.043C11.131 20.287 11.0002 20.492 10.8466 20.6752C11.1338 20.7036 11.4249 20.7191 11.7195 20.7191C16.5349 20.7191 20.4383 16.8157 20.4383 12.0004C20.4383 7.185 16.5342 3.28125 11.7188 3.28125Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_17574_239323' }, { children: _jsx("rect", { width: '17.4375', height: '18', fill: 'white', transform: 'translate(3 3)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimeBalanceTarget;
