var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Body } from '../../Typography';
export var BasicCell = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'Cell' : _b, _c = _a.variant, variant = _c === void 0 ? 'medium' : _c;
    return (_jsx(Body, __assign({ variant: variant }, { children: name }), void 0));
};
