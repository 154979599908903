var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var AbsenceFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '25', height: '24', viewBox: '0 0 25 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_35999_172363)' }, { children: _jsx("path", { d: 'M12.3628 2.00107C9.88803 2.03914 7.82487 3.0823 6.33253 4.23909C4.34274 5.78148 3.24659 7.57089 3.24659 7.57089C2.95476 8.04599 3.20277 8.66867 3.74138 8.81279L11.3879 10.862C11.6097 10.9216 11.7415 11.1495 11.6825 11.3714L9.47869 19.5943C9.35414 20.0309 8.90479 20.29 8.46469 20.1721C8.02022 20.053 7.75645 19.5961 7.8755 19.1516L8.08701 18.3595C8.14638 18.1372 8.00958 17.982 7.78294 18.0218C6.37625 18.2688 5.27788 18.7267 4.47218 19.1956C3.28294 19.8876 2.6753 20.6572 2.6753 20.6572C2.25195 21.2055 2.64342 22.001 3.33611 22H16.6678C17.3597 21.9996 17.7499 21.2049 17.327 20.6572C17.327 20.6572 16.721 19.8876 15.5317 19.1956C14.6966 18.7096 13.5444 18.2363 12.0622 17.9968C11.8351 17.96 11.6982 17.7572 11.7577 17.5349L13.2922 11.8044C13.351 11.5811 13.5802 11.4482 13.8032 11.5082L21.4497 13.5574C21.9882 13.7019 22.5147 13.2879 22.4995 12.7305C22.4995 12.7305 22.4473 10.634 21.4953 8.30333C20.5433 5.97266 18.6102 3.30727 14.9686 2.33149C14.0582 2.08754 13.1877 1.98839 12.3628 2.00107V2.00107ZM14.2297 3.47572C14.368 3.48127 14.5063 3.50049 14.6447 3.53757C16.8593 4.13099 16.9942 8.41111 16.7313 10.1377C16.6967 10.365 16.4685 10.4984 16.2463 10.4388L9.80746 8.7135C9.58519 8.65394 9.45519 8.42479 9.53891 8.21055C10.1348 6.68548 12.1547 3.39249 14.2297 3.47572V3.47572Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_35999_172363' }, { children: _jsx("rect", { width: '20', height: '20', fill: 'white', transform: 'translate(2.5 2)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default AbsenceFilled;
