var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/ChoiceTag.styles';
import { SIZES } from './utils';
export function ChoiceTag(_a) {
    var _b;
    var children = _a.children, style = _a.style, className = _a.className, _c = _a.id, id = _c === void 0 ? 'choiceTag' : _c, _d = _a.size, size = _d === void 0 ? SIZES.Medium : _d, _e = _a.selected, selected = _e === void 0 ? false : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, rest = __rest(_a, ["children", "style", "className", "id", "size", "selected", "disabled"]);
    var styles = useStyles();
    var classes = cx(styles.root, styles[size], (_b = {},
        _b[styles.selected] = selected,
        _b[styles.disabled] = disabled,
        _b["" + String(className)] = !!className,
        _b));
    return (_jsx("div", __assign({ style: style, id: id, className: classes }, rest, { children: children }), void 0));
}
