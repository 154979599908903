var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ArrowDropLeft = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M8.88534 11.2408C8.41973 11.6399 8.41973 12.3602 8.88534 12.7593L13.3488 16.585C13.9974 17.1411 14.9995 16.6801 14.9995 15.8258V8.17423C14.9995 7.31988 13.9974 6.85897 13.3488 7.41498L8.88534 11.2408Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default ArrowDropLeft;
