var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Concierge = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M11.0625 7.5C10.7508 7.5 10.5 7.75078 10.5 8.0625C10.5 8.37422 10.7508 8.625 11.0625 8.625H11.4375V9.40547C8.80312 9.68438 6.75 11.9156 6.75 14.625H17.25C17.25 11.9156 15.1969 9.68438 12.5625 9.40547V8.625H12.9375C13.2492 8.625 13.5 8.37422 13.5 8.0625C13.5 7.75078 13.2492 7.5 12.9375 7.5H12H11.0625ZM6.5625 15.375C6.25078 15.375 6 15.6258 6 15.9375C6 16.2492 6.25078 16.5 6.5625 16.5H17.4375C17.7492 16.5 18 16.2492 18 15.9375C18 15.6258 17.7492 15.375 17.4375 15.375H6.5625Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Concierge;
