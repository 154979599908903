var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
export var ReactionEnum;
(function (ReactionEnum) {
    ReactionEnum["JobdoneAngry"] = "JOBDONE_ANGRY";
    ReactionEnum["JobdoneCare"] = "JOBDONE_CARE";
    ReactionEnum["JobdoneCelebrate"] = "JOBDONE_CELEBRATE";
    ReactionEnum["JobdoneCry"] = "JOBDONE_CRY";
    ReactionEnum["JobdoneFunny"] = "JOBDONE_FUNNY";
    ReactionEnum["JobdoneHaha"] = "JOBDONE_HAHA";
    ReactionEnum["JobdoneInsightful"] = "JOBDONE_INSIGHTFUL";
    ReactionEnum["JobdoneLike"] = "JOBDONE_LIKE";
    ReactionEnum["JobdoneLove"] = "JOBDONE_LOVE";
    ReactionEnum["JobdoneSupport"] = "JOBDONE_SUPPORT";
    ReactionEnum["JobdoneWow"] = "JOBDONE_WOW";
    ReactionEnum["JobdoneThumbsUp"] = "JOBDONE_THUMBS_UP";
    ReactionEnum["JobdonePrayingHands"] = "JOBDONE_PRAYING_HANDS";
    ReactionEnum["JobdoneFingerHeart"] = "JOBDONE_FINGER_HEART";
    ReactionEnum["JobdoneThumbsDown"] = "JOBDONE_THUMBS_DOWN";
})(ReactionEnum || (ReactionEnum = {}));
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        backgroundColor: theme.palette.common.white,
        transition: '.1s',
    },
    emoji: {
        width: 24,
        height: 24,
    },
    chatEmoji: {
        width: 24,
        height: 24,
        marginTop: 8,
    },
}); });
var Reaction = function (_a) {
    var style = _a.style, className = _a.className, type = _a.type, count = _a.count, isSelected = _a.isSelected, onClick = _a.onClick, isChatMessageReactions = _a.isChatMessageReactions, rest = __rest(_a, ["style", "className", "type", "count", "isSelected", "onClick", "isChatMessageReactions"]);
    var classes = useStyles();
    var getEmoji = function () {
        if (type === ReactionEnum.JobdoneAngry)
            return 'angry';
        if (type === ReactionEnum.JobdoneCare)
            return 'care';
        if (type === ReactionEnum.JobdoneCry)
            return 'sad';
        if (type === ReactionEnum.JobdoneHaha)
            return 'haha';
        if (type === ReactionEnum.JobdoneWow)
            return 'wow';
        if (type === ReactionEnum.JobdoneThumbsUp)
            return 'thumbsUp';
        if (type === ReactionEnum.JobdonePrayingHands)
            return 'thankfull';
        if (type === ReactionEnum.JobdoneFingerHeart)
            return 'fingerHeart';
        if (type === ReactionEnum.JobdoneThumbsDown)
            return 'thumbsDown';
        if (type === ReactionEnum.JobdoneLike)
            return 'like';
        if (type === ReactionEnum.JobdoneCelebrate)
            return 'celebrate';
        if (type === ReactionEnum.JobdoneSupport)
            return 'support';
        if (type === ReactionEnum.JobdoneLove)
            return 'love';
        if (type === ReactionEnum.JobdoneInsightful)
            return 'insightful';
        if (type === ReactionEnum.JobdoneFunny)
            return 'funny';
        return '';
    };
    if (isChatMessageReactions) {
        return (_jsx("img", { src: "/static/emojis/" + getEmoji() + ".png", alt: getEmoji(), className: classes.chatEmoji }, void 0));
    }
    if (!count) {
        return null;
    }
    return (_jsx("div", __assign({ className: cx(className, classes.root), style: style }, rest, { children: _jsx("img", { src: "/static/emojis/" + getEmoji() + ".png", alt: getEmoji(), className: classes.emoji }, void 0) }), void 0));
};
export default Reaction;
