var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { createElement as _createElement } from "react";
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect } from 'react';
import { useTable, useSortBy, useExpanded, useRowSelect, } from 'react-table';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStyles from './styles/Table.styles';
import IndeterminateCheckbox from './IndeterminateCheckbox';
export function Table(_a) {
    var columns = _a.columns, data = _a.data, _b = _a.className, className = _b === void 0 ? 'table-root' : _b, _c = _a.tableHeadClassname, tableHeadClassname = _c === void 0 ? 'table-head' : _c, onClickRow = _a.onClickRow, getSelectedRows = _a.getSelectedRows, isMultiSelect = _a.isMultiSelect, _d = _a.hasTotalCalculationRow, hasTotalCalculationRow = _d === void 0 ? false : _d, _e = _a.isHorizontalScroll, isHorizontalScroll = _e === void 0 ? false : _e, _f = _a.isTimeTrackingAndReportsTable, isTimeTrackingAndReportsTable = _f === void 0 ? false : _f, _g = _a.isRowHeightMin, isRowHeightMin = _g === void 0 ? false : _g, _h = _a.selectSubRows, selectSubRows = _h === void 0 ? false : _h, _j = _a.expanded, expanded = _j === void 0 ? {} : _j, _k = _a.infiteScrollHasMore, infiteScrollHasMore = _k === void 0 ? false : _k, _l = _a.isInfiniteScroll, isInfiniteScroll = _l === void 0 ? false : _l, _m = _a.fetchMore, fetchMore = _m === void 0 ? function () { } : _m, _o = _a.infiteScrollLoading, infiteScrollLoading = _o === void 0 ? _jsx(_Fragment, {}, void 0) : _o, bodyCellCustomClassname = _a.bodyCellCustomClassname, headCellCustomClassname = _a.headCellCustomClassname, _p = _a.hasGrandTotalCalculationRow, hasGrandTotalCalculationRow = _p === void 0 ? false : _p, rest = __rest(_a, ["columns", "data", "className", "tableHeadClassname", "onClickRow", "getSelectedRows", "isMultiSelect", "hasTotalCalculationRow", "isHorizontalScroll", "isTimeTrackingAndReportsTable", "isRowHeightMin", "selectSubRows", "expanded", "infiteScrollHasMore", "isInfiniteScroll", "fetchMore", "infiteScrollLoading", "bodyCellCustomClassname", "headCellCustomClassname", "hasGrandTotalCalculationRow"]);
    var styles = useStyles();
    var _columns = React.useMemo(function () { return columns; }, [columns]);
    var _data = React.useMemo(function () { return data; }, [data]);
    var tableInstance = useTable({
        columns: _columns,
        data: _data || [],
        autoResetExpanded: false,
        autoResetSelectedRows: false,
        initialState: {
            expanded: expanded,
        },
    }, useSortBy, useExpanded, useRowSelect, function (hooks) {
        if (isMultiSelect) {
            // eslint-disable-next-line no-param-reassign
            hooks.getToggleAllRowsSelectedProps = [
                function (props, _a) {
                    var instance = _a.instance;
                    return [
                        props,
                        {
                            onChange: function () {
                                instance.rows.forEach(function (row) {
                                    var _rows = __spreadArray([], __read(instance.rows));
                                    _rows.filter(function (_row) { return !!_row.original.isDateRow; });
                                    row.toggleRowSelected(
                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                    !_rows.every(function (row) { return row.isSelected; }));
                                });
                            },
                            style: { cursor: 'pointer' },
                            checked: instance.rows.every(function (row) { return row.isSelected; }),
                            title: 'Toggle All Rows Selected',
                            indeterminate: Boolean(!instance.isAllRowsSelected
                                && Object.keys(instance.state.selectedRowIds).length),
                        },
                    ];
                },
            ];
            hooks.visibleColumns.push(function (selectionColumns) { return __spreadArray([
                {
                    id: 'row-select',
                    // eslint-disable-next-line react/prop-types
                    Header: function (_a) {
                        var getToggleAllRowsSelectedProps = _a.getToggleAllRowsSelectedProps;
                        return (_jsx(_Fragment, { children: _jsx(IndeterminateCheckbox, __assign({ indeterminate: getToggleAllRowsSelectedProps().indeterminate }, getToggleAllRowsSelectedProps()), void 0) }, void 0));
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Cell: function (_a) {
                        var row = _a.row;
                        if (row.depth > 0) {
                            if (selectSubRows) {
                                return (_jsx(IndeterminateCheckbox, __assign({ disabled: row.original.isDateRow || row.original.isEmptyDate }, row.getToggleRowSelectedProps()), void 0));
                            }
                            return null;
                        }
                        return (_jsx(IndeterminateCheckbox, __assign({ disabled: row.original.isDateRow || row.original.isEmptyDate }, row.getToggleRowSelectedProps()), void 0));
                    },
                }
            ], __read(selectionColumns)); });
        }
    });
    var getTableProps = tableInstance.getTableProps, getTableBodyProps = tableInstance.getTableBodyProps, headerGroups = tableInstance.headerGroups, rows = tableInstance.rows, prepareRow = tableInstance.prepareRow, selectedFlatRows = tableInstance.selectedFlatRows;
    useEffect(function () {
        if (getSelectedRows) {
            getSelectedRows(selectedFlatRows.filter(function (row) {
                if (row.original.isDateRow) {
                    return false;
                }
                if (row.original.isEmptyDate) {
                    return false;
                }
                return true;
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows]);
    var renderTable = function () {
        var _a, _b;
        return (_jsx("div", __assign({ className: styles.tableContainer }, { children: _jsx(AnimatePresence, { children: _jsxs(motion.table, __assign({ initial: { opacity: 0 }, exit: { opacity: 0 }, animate: { opacity: 1 } }, getTableProps(), { className: cx(styles.table, (_a = {},
                        _a[className] = !!className,
                        _a[styles.tableWithBorderRadius] = hasTotalCalculationRow,
                        _a[styles.horizontalScroll] = isHorizontalScroll,
                        _a)) }, rest, { children: [_jsx("thead", __assign({ className: cx(styles.head, (_b = {}, _b[tableHeadClassname] = !!tableHeadClassname, _b)) }, { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) {
                                    var _a;
                                    return (_createElement("th", __assign({}, column.getHeaderProps({
                                        style: {
                                            width: column.id === 'row-select' ? '50px' : column.width,
                                            minWidth: column.id === 'row-select' ? 0 : column.minWidth,
                                        },
                                    }), { key: column.id, className: cx(styles.headCell, (_a = {},
                                            _a[String(headCellCustomClassname)] = !!headCellCustomClassname,
                                            _a)) }), column.render('Header')));
                                }) }), void 0)); }) }), void 0), _jsxs("tbody", __assign({}, getTableBodyProps(), { children: [_jsx("tr", { className: styles.space }, void 0), rows.map(function (row, i) {
                                    var _a, _b, _c, _d;
                                    var _e, _f;
                                    prepareRow(row);
                                    if (row.original.isEmptyDate) {
                                        return (_createElement("tr", __assign({}, row.getRowProps(), { 
                                            // eslint-disable-next-line react/no-array-index-key
                                            key: String(row.original.id) + "-" + i, className: cx(styles.row, styles.isEmptyDate, (_a = {},
                                                _a[styles.totalRow] = hasTotalCalculationRow && rows.length - 1 === i,
                                                _a[styles.noBorderBottom] = (_e = rows[i + 1]) === null || _e === void 0 ? void 0 : _e.original.isDateRow,
                                                _a)) }), row.cells.map(function (cell, cellIndex) {
                                            var _a;
                                            return (_jsx("td", __assign({}, cell.getCellProps({
                                                style: {
                                                    width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                                    minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                                },
                                            }), { className: cx(styles.cell, (_a = {},
                                                    _a[styles.endBalanceColumn] = cell.column.id.includes('endBalance'),
                                                    _a[styles.noPaddingColumn] = cell.column.id.includes('difference'),
                                                    _a[styles.darkerBlue] = rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                                    _a[styles.grandTotalEndBalance] = rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                                    _a[styles.timeTrackingAndReport] = isTimeTrackingAndReportsTable,
                                                    _a[styles.minRowHeight] = isRowHeightMin,
                                                    _a[String(bodyCellCustomClassname)] = !!bodyCellCustomClassname,
                                                    _a)) }, { children: cellIndex >= 5 ? '' : cell.render('Cell') }), void 0));
                                        })));
                                    }
                                    if (row.original.isDateRow) {
                                        return (_createElement("tr", __assign({}, row.getRowProps(), { 
                                            // eslint-disable-next-line react/no-array-index-key
                                            key: String(row.original.id) + "-" + i, className: cx(styles.row, (_b = {},
                                                _b[styles.dateRow] = !!row.original.isDateRow,
                                                _b)) }), row.cells.map(function (cell, cellIndex) {
                                            var _a;
                                            return (_jsx("td", __assign({}, cell.getCellProps({
                                                style: {
                                                    width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                                    minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                                },
                                            }), { className: cx(styles.cell, (_a = {},
                                                    _a[styles.endBalanceColumn] = cell.column.id.includes('endBalance'),
                                                    _a[styles.noPaddingColumn] = cell.column.id.includes('difference'),
                                                    _a[styles.darkerBlue] = rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                                    _a[styles.grandTotalEndBalance] = rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                                    _a[styles.rowLeftSide] = cellIndex === 0,
                                                    _a[styles.rowRightSide] = cellIndex === (row === null || row === void 0 ? void 0 : row.cells.length) - 1,
                                                    _a[styles.timeTrackingAndReportDateRow] = isTimeTrackingAndReportsTable,
                                                    _a[String(bodyCellCustomClassname)] = !!bodyCellCustomClassname,
                                                    _a)) }, { children: cellIndex >= 3 ? '' : cell.render('Cell') }), void 0));
                                        })));
                                    }
                                    if (row.original.isEmployeeRow) {
                                        return (_createElement("tr", __assign({}, row.getRowProps(), { 
                                            // eslint-disable-next-line react/no-array-index-key
                                            key: String(row.original.id) + "-" + i, className: cx(styles.row, (_c = {},
                                                _c[styles.employeeRow] = !!row.original.isEmployeeRow,
                                                _c)) }), row.cells.map(function (cell, cellIndex) {
                                            var _a;
                                            return (_jsx("td", __assign({}, cell.getCellProps({
                                                style: {
                                                    width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                                    minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                                },
                                            }), { className: cx(styles.cell, (_a = {},
                                                    _a[styles.endBalanceColumn] = cell.column.id.includes('endBalance'),
                                                    _a[styles.noPaddingColumn] = cell.column.id.includes('difference'),
                                                    _a[styles.darkerBlue] = rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                                    _a[styles.grandTotalEndBalance] = rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                                    _a[styles.rowLeftSide] = cellIndex === 0,
                                                    _a[styles.rowRightSide] = cellIndex === (row === null || row === void 0 ? void 0 : row.cells.length) - 1,
                                                    _a[styles.timeTrackingAndReportDateRow] = isTimeTrackingAndReportsTable,
                                                    _a[String(bodyCellCustomClassname)] = !!bodyCellCustomClassname,
                                                    _a)) }, { children: cellIndex >= 3 ? '' : cell.render('Cell') }), void 0));
                                        })));
                                    }
                                    return (_createElement("tr", __assign({}, row.getRowProps(), { 
                                        // eslint-disable-next-line react/no-array-index-key
                                        key: String(row.original.id) + "-" + i, className: cx(styles.row, (_d = {},
                                            _d[styles.totalRow] = hasTotalCalculationRow && rows.length - 1 === i,
                                            _d[styles.noBorderBottom] = (_f = rows[i + 1]) === null || _f === void 0 ? void 0 : _f.original.isDateRow,
                                            _d)), onClick: function () {
                                            if (row.original.secondaryId && row.original.rowType) {
                                                onClickRow(row.original.id, row.original.rowType, row.original.secondaryId);
                                            }
                                            else if (row.original.rowType) {
                                                onClickRow(row.original.id, row.original.rowType);
                                            }
                                            else {
                                                onClickRow(row.original.id);
                                            }
                                        } }), row.cells.map(function (cell) {
                                        var _a;
                                        return (_jsx("td", __assign({}, cell.getCellProps({
                                            style: {
                                                width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                                minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                            },
                                        }), { className: cx(styles.cell, (_a = {},
                                                _a[styles.endBalanceColumn] = cell.column.id.includes('endBalance'),
                                                _a[styles.noPaddingColumn] = cell.column.id.includes('difference'),
                                                _a[styles.darkerBlue] = rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                                _a[styles.grandTotalEndBalance] = rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                                _a[styles.timeTrackingAndReport] = isTimeTrackingAndReportsTable,
                                                _a[styles.minRowHeight] = isRowHeightMin,
                                                _a[String(bodyCellCustomClassname)] = !!bodyCellCustomClassname,
                                                _a)) }, { children: cell.render('Cell') }), void 0));
                                    })));
                                })] }), void 0)] }), void 0) }, void 0) }), void 0));
    };
    if (isInfiniteScroll) {
        return (_jsx(InfiniteScroll, __assign({ dataLength: rows.length, next: fetchMore, hasMore: infiteScrollHasMore, loader: infiteScrollLoading }, { children: renderTable() }), void 0));
    }
    return renderTable();
}
