var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cx from 'classnames';
import { Radio as MuiRadio, FormControlLabel } from '@material-ui/core';
import useStyles from './styles/Radio.styles';
import { LabelPlacements } from './utils';
export var Radio = forwardRef(function (_a, ref) {
    var className = _a.className, label = _a.label, _b = _a.labelPlacement, labelPlacement = _b === void 0 ? LabelPlacements.end : _b, labelClassName = _a.labelClassName, radioClassname = _a.radioClassname, parentContainerClassname = _a.parentContainerClassname, props = __rest(_a, ["className", "label", "labelPlacement", "labelClassName", "radioClassname", "parentContainerClassname"]);
    var classes = useStyles({ labelPlacement: labelPlacement });
    return (_jsx("div", __assign({ className: parentContainerClassname }, { children: _jsx(FormControlLabel, { classes: {
                label: cx(classes.label, labelClassName),
            }, label: label, labelPlacement: labelPlacement, control: (_jsx(MuiRadio, __assign({ inputRef: ref, disableRipple: true, className: cx(classes.root, radioClassname), color: 'default', checkedIcon: _jsx("span", { className: cx(classes.icon, classes.checkedIcon) }, void 0), icon: _jsx("span", { className: classes.icon }, void 0) }, props), void 0)) }, void 0) }), void 0));
});
