var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var PersonChalkboardSolid = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_29135_34294)' }, { children: _jsx("path", { d: 'M8.14925 7.53125C8.14925 6.9617 8.61956 6.5 9.19925 6.5C9.77894 6.5 10.2492 6.9617 10.2492 7.53125C10.2492 8.1008 9.77894 8.5625 9.19925 8.5625C8.61956 8.5625 8.14925 8.1008 8.14925 7.53125ZM8.32425 17.5C7.93706 17.5 7.62425 17.1928 7.62425 16.8125V12.0193L6.99928 13.042C6.80022 13.3664 6.37015 13.4717 6.03875 13.2762C5.70734 13.0807 5.60015 12.6596 5.79944 12.333L7.07409 10.2512C7.45362 9.63027 8.13612 9.25 8.87331 9.25H11.9992V7.53125C11.9992 6.9617 12.4696 6.50001 13.0492 6.50001H17.9492C18.5289 6.50001 18.9992 6.9617 18.9992 7.53125V12.3438C18.9992 12.9131 18.5289 13.375 17.9492 13.375H13.0492C12.4696 13.375 11.9992 12.9131 11.9992 12.3438V11.3125H13.3992V12H17.5992V7.875H13.3992V9.25H13.7492C14.1364 9.25 14.4492 9.55723 14.4492 9.9375C14.4492 10.3178 14.1364 10.625 13.7492 10.625H10.7742V16.8125C10.7742 17.1928 10.4614 17.5 10.0742 17.5C9.68706 17.5 9.37425 17.1928 9.37425 16.8125V14.0625H9.02425V16.8125C9.02425 17.1928 8.71144 17.5 8.32425 17.5Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_29135_34294' }, { children: _jsx("rect", { width: '14', height: '11', fill: 'white', transform: 'translate(5 6.5)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default PersonChalkboardSolid;
