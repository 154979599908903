import { useRouter } from 'next/router';
import { useEffect } from 'react';

const unprotectedUrls = [
  'login',
  'register',
  'select-organization',
  '404',
  '500',
  'create-business',
  'download-app',
  'reset-password',
  'join-organization',
  'circle-post-mobile',
  'circle-post-comment-mobile',
  'create-circle-post-comment-mobile',
  'profile-settings',
];

export const useSessionId = () => {
  const { query, push, pathname } = useRouter();
  const sessionId = query?.sessionId as string;

  const [organizationId, organizationUserId] = sessionId?.split('.') || [];

  useEffect(() => {
    if (
      (!organizationId || !organizationUserId)
      && (
        !unprotectedUrls.includes(pathname.split('/')[1])
        && pathname !== '/'
      )
    ) {
      localStorage.setItem('shouldAutoSelectOrganization', JSON.stringify(true));
      void push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, organizationUserId]);

  return {
    organizationId,
    organizationUserId,
  };
};
