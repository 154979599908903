var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var AwaitingApprovalCircle = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '18', height: '18', viewBox: '0 0 18 18', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M8.9987 16.6663C13.2329 16.6663 16.6654 13.2339 16.6654 8.99967C16.6654 4.76549 13.2329 1.33301 8.9987 1.33301C4.76451 1.33301 1.33203 4.76549 1.33203 8.99967C1.33203 13.2339 4.76451 16.6663 8.9987 16.6663Z', fill: '#F99C10', stroke: 'white', strokeWidth: '2' }, void 0), _jsx("path", { d: 'M5.66797 8.99967C5.66797 8.63148 5.96645 8.33301 6.33464 8.33301H11.668C12.0362 8.33301 12.3346 8.63148 12.3346 8.99967C12.3346 9.36786 12.0362 9.66634 11.668 9.66634H6.33464C5.96645 9.66634 5.66797 9.36786 5.66797 8.99967Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default AwaitingApprovalCircle;
