var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var SickDay = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_13771_168933)' }, { children: _jsx("path", { d: 'M17.4431 13.8382L14.2572 12L17.4431 10.1618C17.6226 10.0582 17.684 9.82898 17.5804 9.64945L16.8299 8.35031C16.7264 8.17102 16.4969 8.10938 16.3174 8.21297L13.1315 10.0512V6.375C13.1315 6.16781 12.9635 6 12.7563 6H11.2553C11.0481 6 10.8801 6.16781 10.8801 6.375V10.0514L7.69424 8.2132C7.51471 8.10961 7.28526 8.17125 7.18167 8.35055L6.4312 9.64945C6.3276 9.82875 6.38901 10.0582 6.56854 10.1618L9.7544 12L6.56854 13.8382C6.38901 13.9418 6.3276 14.1713 6.4312 14.3505L7.18167 15.6497C7.28526 15.829 7.51471 15.8904 7.69424 15.787L10.8801 13.9488V17.625C10.8801 17.8322 11.0481 18 11.2553 18H12.7563C12.9635 18 13.1315 17.8322 13.1315 17.625V13.9486L16.3174 15.7868C16.4969 15.8904 16.7264 15.829 16.8299 15.6495L17.5804 14.3503C17.684 14.171 17.6226 13.9418 17.4431 13.8382Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_13771_168933' }, { children: _jsx("rect", { width: '11.25', height: '12', fill: 'white', transform: 'translate(6.38086 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default SickDay;
