var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ScheduleChart = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M5 4C5 3.44772 5.44772 3 6 3C6.55228 3 7 3.44772 7 4V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V4Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10V20C11 20.5523 10.5523 21 10 21C9.44772 21 9 20.5523 9 20V10Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14V20C15 20.5523 14.5523 21 14 21C13.4477 21 13 20.5523 13 20V14Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V6Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default ScheduleChart;
