var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import useStyles from './styles';
var MenuPortal = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M17 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V19ZM12.71 16.29C12.5945 16.1696 12.4508 16.08 12.2919 16.0293C12.1329 15.9787 11.9638 15.9686 11.8 16L11.62 16.06C11.5563 16.0819 11.4957 16.1122 11.44 16.15L11.29 16.27C11.199 16.3651 11.1276 16.4772 11.08 16.6C11.0034 16.7821 10.9825 16.9828 11.0199 17.1768C11.0572 17.3708 11.1512 17.5494 11.29 17.69C11.3895 17.778 11.5005 17.852 11.62 17.91C11.8031 17.9853 12.0044 18.0046 12.1984 17.9655C12.3924 17.9263 12.5705 17.8304 12.71 17.69C12.8923 17.5062 12.9963 17.2589 13 17C13.0034 16.8688 12.976 16.7387 12.92 16.62C12.8694 16.4988 12.7983 16.3872 12.71 16.29Z', fill: '#7F8897' }, void 0) }), void 0) }), void 0)); };
export default MenuPortal;
