var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Star = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_3088:365)' }, { children: _jsx("path", { d: 'M11.3274 6.41724L9.79689 9.52037L6.37267 10.0196C5.7586 10.1086 5.51251 10.8657 5.95782 11.2993L8.43517 13.7133L7.84923 17.1235C7.74376 17.7399 8.39298 18.2016 8.93673 17.9133L12 16.3032L15.0633 17.9133C15.607 18.1993 16.2563 17.7399 16.1508 17.1235L15.5649 13.7133L18.0422 11.2993C18.4875 10.8657 18.2414 10.1086 17.6274 10.0196L14.2031 9.52037L12.6727 6.41724C12.3984 5.86412 11.6039 5.85708 11.3274 6.41724Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_3088:365' }, { children: _jsx("rect", { width: '13.5', height: '12', fill: 'white', transform: 'translate(5.25 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Star;
