var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Note = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_29104_331388)' }, { children: _jsx("path", { d: 'M14.3125 13.5H17.5V7.3125C17.5 7.00078 17.2492 6.75 16.9375 6.75H7.5625C7.25078 6.75 7 7.00078 7 7.3125V16.6875C7 16.9992 7.25078 17.25 7.5625 17.25H13.75V14.0625C13.75 13.7531 14.0031 13.5 14.3125 13.5ZM17.3359 14.7891L15.0391 17.0859C14.9336 17.1914 14.7906 17.25 14.6406 17.25H14.5V14.25H17.5V14.393C17.5 14.5406 17.4414 14.6836 17.3359 14.7891Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_29104_331388' }, { children: _jsx("rect", { width: '10.5', height: '12', fill: 'white', transform: 'translate(7 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Note;
