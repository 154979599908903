var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimesheetFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_40216_242474)' }, { children: _jsx("path", { d: 'M6.5 16.875C6.5 17.4961 7.0279 18 7.67857 18H16.3214C16.9721 18 17.5 17.4961 17.5 16.875V10.5H6.5V16.875ZM8.07143 12.375C8.07143 12.1687 8.24821 12 8.46429 12H10.8214C11.0375 12 11.2143 12.1687 11.2143 12.375V14.625C11.2143 14.8313 11.0375 15 10.8214 15H8.46429C8.24821 15 8.07143 14.8313 8.07143 14.625V12.375ZM16.3214 7.5H15.1429V6.375C15.1429 6.16875 14.9661 6 14.75 6H13.9643C13.7482 6 13.5714 6.16875 13.5714 6.375V7.5H10.4286V6.375C10.4286 6.16875 10.2518 6 10.0357 6H9.25C9.03393 6 8.85714 6.16875 8.85714 6.375V7.5H7.67857C7.0279 7.5 6.5 8.00391 6.5 8.625V9.75H17.5V8.625C17.5 8.00391 16.9721 7.5 16.3214 7.5Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_40216_242474' }, { children: _jsx("rect", { width: '11', height: '12', fill: 'white', transform: 'translate(6.5 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimesheetFilled;
