var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Like = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M6.44336 2.9117C6.62484 2.36724 7.13436 2 7.70827 2H8.56109C9.29747 2 9.89443 2.59695 9.89443 3.33333V6H13.4824C14.4736 6 15.1182 7.04309 14.675 7.92962L12.0083 13.263C11.7825 13.7147 11.3208 14 10.8157 14H6.30645C5.80142 14 5.33974 13.7147 5.11388 13.263L4.0883 11.2118C3.93016 10.8955 3.90414 10.5294 4.01596 10.1939L6.44336 2.9117Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3.43858 6.03421C3.78787 6.15064 3.97665 6.52819 3.86022 6.87749L2.70153 10.3535C2.64562 10.5213 2.65863 10.7044 2.7377 10.8625L3.82405 13.0352C3.98871 13.3645 3.85522 13.765 3.5259 13.9296C3.19658 14.0943 2.79614 13.9608 2.63148 13.6315L1.54513 11.4588C1.30791 10.9844 1.26888 10.4351 1.43662 9.93191L2.59531 6.45585C2.71174 6.10655 3.08928 5.91778 3.43858 6.03421Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Like;
