var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Archive = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3 4V6H21V4H3ZM1.56789 2.56789C1.95567 2.18011 2.47275 2 3 2H21C21.5272 2 22.0443 2.18011 22.4321 2.56789C22.8199 2.95567 23 3.47275 23 4V6C23 6.52725 22.8199 7.04433 22.4321 7.43211C22.0443 7.81989 21.5272 8 21 8H3C2.47275 8 1.95567 7.81989 1.56789 7.43211C1.18011 7.04433 1 6.52725 1 6V4C1 3.47275 1.18011 2.95567 1.56789 2.56789Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8 11C8 10.4477 8.44772 10 9 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H9C8.44772 12 8 11.5523 8 11Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3.30078 6C3.85307 6 4.30078 6.44772 4.30078 7V19C4.30078 19.5477 4.75307 20 5.30078 20H18.7008C19.2485 20 19.7008 19.5477 19.7008 19V7C19.7008 6.44772 20.1485 6 20.7008 6C21.2531 6 21.7008 6.44772 21.7008 7V19C21.7008 20.6523 20.3531 22 18.7008 22H5.30078C3.6485 22 2.30078 20.6523 2.30078 19V7C2.30078 6.44772 2.7485 6 3.30078 6Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Archive;
