var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var BDay = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M6.75 16.5C6.75 16.9148 7.08516 17.25 7.5 17.25H11.25V13.5H6.75V16.5ZM12.75 17.25H16.5C16.9148 17.25 17.25 16.9148 17.25 16.5V13.5H12.75V17.25ZM17.25 9.75H16.2633C16.4086 9.46641 16.5 9.15234 16.5 8.8125C16.5 7.67578 15.5742 6.75 14.4375 6.75C13.4625 6.75 12.832 7.24922 12.0234 8.35078C11.2148 7.24922 10.5844 6.75 9.60938 6.75C8.47266 6.75 7.54688 7.67578 7.54688 8.8125C7.54688 9.15234 7.63594 9.46641 7.78359 9.75H6.75C6.33516 9.75 6 10.0852 6 10.5V12.375C6 12.5813 6.16875 12.75 6.375 12.75H17.625C17.8313 12.75 18 12.5813 18 12.375V10.5C18 10.0852 17.6648 9.75 17.25 9.75ZM9.60703 9.75C9.08906 9.75 8.66953 9.33047 8.66953 8.8125C8.66953 8.29453 9.08906 7.875 9.60703 7.875C10.0734 7.875 10.418 7.95234 11.625 9.75H9.60703ZM14.4375 9.75H12.4195C13.6242 7.95703 13.9594 7.875 14.4375 7.875C14.9555 7.875 15.375 8.29453 15.375 8.8125C15.375 9.33047 14.9555 9.75 14.4375 9.75Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default BDay;
