var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Correction = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_17574_239335)' }, { children: _jsx("path", { d: 'M21.4509 12.7008C22.1831 11.9686 22.1831 10.7814 21.4509 10.0492L15.2009 3.79918C14.4686 3.06696 13.2815 3.06692 12.5492 3.79918L2.54917 13.7992C1.81694 14.5314 1.81694 15.7186 2.54917 16.4508L6.29917 20.2008C6.65082 20.5525 7.12774 20.75 7.62503 20.75H21.5313C21.7901 20.75 22 20.5401 22 20.2813V18.7188C22 18.4599 21.7901 18.25 21.5313 18.25H15.9017L21.4509 12.7008ZM9.62948 10.2545L14.9956 15.6206L12.3662 18.25H7.88394L4.75894 15.125L9.62948 10.2545Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_17574_239335' }, { children: _jsx("rect", { width: '20', height: '20', fill: 'white', transform: 'translate(2 2)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Correction;
