var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var PhoneAlt = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_3088:229)' }, { children: _jsx("path", { d: 'M17.6576 14.4797L15.0326 13.3547C14.9204 13.3069 14.7958 13.2969 14.6775 13.326C14.5591 13.3552 14.4534 13.422 14.3763 13.5164L13.2138 14.9368C11.3894 14.0765 9.92114 12.6083 9.06094 10.7839L10.4812 9.62136C10.5759 9.54441 10.6428 9.43873 10.672 9.32032C10.7012 9.20191 10.691 9.07721 10.643 8.96511L9.51797 6.34011C9.46526 6.21927 9.37204 6.1206 9.25438 6.06113C9.13672 6.00166 9.00199 5.98511 8.87344 6.01433L6.43594 6.57683C6.31199 6.60545 6.20141 6.67524 6.12223 6.7748C6.04306 6.87436 5.99997 6.99783 6 7.12503C6 13.1368 10.8727 18 16.875 18C17.0022 18.0001 17.1258 17.957 17.2254 17.8779C17.325 17.7987 17.3948 17.6881 17.4234 17.5641L17.9859 15.1266C18.015 14.9974 17.9981 14.8621 17.9381 14.7441C17.8782 14.626 17.779 14.5325 17.6576 14.4797Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_3088:229' }, { children: _jsx("rect", { width: '12', height: '12', fill: 'white', transform: 'translate(6 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default PhoneAlt;
