var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Users = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M7.8 11.2C8.46188 11.2 9 10.6619 9 9.99998C9 9.3381 8.46188 8.79998 7.8 8.79998C7.13813 8.79998 6.6 9.3381 6.6 9.99998C6.6 10.6619 7.13813 11.2 7.8 11.2ZM16.2 11.2C16.8619 11.2 17.4 10.6619 17.4 9.99998C17.4 9.3381 16.8619 8.79998 16.2 8.79998C15.5381 8.79998 15 9.3381 15 9.99998C15 10.6619 15.5381 11.2 16.2 11.2ZM16.8 11.8H15.6C15.27 11.8 14.9719 11.9331 14.7544 12.1487C15.51 12.5631 16.0463 13.3112 16.1625 14.2H17.4C17.7319 14.2 18 13.9319 18 13.6V13C18 12.3381 17.4619 11.8 16.8 11.8ZM12 11.8C13.1606 11.8 14.1 10.8606 14.1 9.69998C14.1 8.53935 13.1606 7.59998 12 7.59998C10.8394 7.59998 9.9 8.53935 9.9 9.69998C9.9 10.8606 10.8394 11.8 12 11.8ZM13.44 12.4H13.2844C12.8944 12.5875 12.4613 12.7 12 12.7C11.5388 12.7 11.1075 12.5875 10.7156 12.4H10.56C9.3675 12.4 8.4 13.3675 8.4 14.56V15.1C8.4 15.5968 8.80313 16 9.3 16H14.7C15.1969 16 15.6 15.5968 15.6 15.1V14.56C15.6 13.3675 14.6325 12.4 13.44 12.4ZM9.24563 12.1487C9.02813 11.9331 8.73 11.8 8.4 11.8H7.2C6.53813 11.8 6 12.3381 6 13V13.6C6 13.9319 6.26812 14.2 6.6 14.2H7.83563C7.95375 13.3112 8.49 12.5631 9.24563 12.1487Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Users;
