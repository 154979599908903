var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Flash = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M13.3821 2.07564C13.8048 2.25 14.0554 2.68885 13.9907 3.1415L13.1538 9.00008H19.2008C19.579 9.00008 19.9249 9.21349 20.0946 9.55156C20.2642 9.88963 20.2286 10.2945 20.0025 10.5977L11.8025 21.5977C11.5293 21.9643 11.0421 22.0989 10.6195 21.9245C10.1968 21.7502 9.94617 21.3113 10.0108 20.8587L10.8478 15.0001H4.80079C4.42254 15.0001 4.07666 14.7867 3.90701 14.4486C3.73736 14.1105 3.77298 13.7057 3.99904 13.4024L12.199 2.40241C12.4723 2.03582 12.9594 1.90128 13.3821 2.07564Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Flash;
