var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Unlock = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M17.417 4.16797C15.5763 4.16797 14.084 5.66025 14.084 7.50097V10.001C14.084 10.5533 13.6363 11.001 13.084 11.001C12.5317 11.001 12.084 10.5533 12.084 10.001V7.50097C12.084 4.55568 14.4717 2.16797 17.417 2.16797C20.3623 2.16797 22.75 4.55568 22.75 7.50097V9.66797C22.75 10.2203 22.3023 10.668 21.75 10.668C21.1977 10.668 20.75 10.2203 20.75 9.66797V7.50097C20.75 5.66025 19.2577 4.16797 17.417 4.16797Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M4.29297 11C3.74025 11 3.29297 11.4473 3.29297 12V19C3.29297 19.5527 3.74025 20 4.29297 20H14.293C14.8457 20 15.293 19.5527 15.293 19V12C15.293 11.4473 14.8457 11 14.293 11H4.29297ZM14.293 22H4.29297C2.63568 22 1.29297 20.6573 1.29297 19V12C1.29297 10.3427 2.63568 9 4.29297 9H14.293C15.9503 9 17.293 10.3427 17.293 12V19C17.293 20.6573 15.9503 22 14.293 22Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9.29102 13C9.8433 13 10.291 13.4477 10.291 14V17C10.291 17.5523 9.8433 18 9.29102 18C8.73873 18 8.29102 17.5523 8.29102 17V14C8.29102 13.4477 8.73873 13 9.29102 13Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Unlock;
