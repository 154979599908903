var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Education = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_13771_168937)' }, { children: _jsx("path", { d: 'M18.6136 9.79146L12.5118 7.95025C12.1793 7.84992 11.8205 7.84992 11.4882 7.95025L5.3862 9.79146C4.87127 9.94679 4.87127 10.6156 5.3862 10.7709L6.44998 11.0919C6.21658 11.3753 6.07308 11.721 6.05886 12.0995C5.8482 12.2181 5.69989 12.4321 5.69989 12.6876C5.69989 12.9192 5.82414 13.114 6.00308 13.2386L5.44461 15.7068C5.39605 15.9214 5.5623 16.1251 5.78608 16.1251H7.01348C7.23748 16.1251 7.40373 15.9214 7.35517 15.7068L6.7967 13.2386C6.97564 13.114 7.09989 12.9192 7.09989 12.6876C7.09989 12.439 6.95836 12.231 6.75733 12.1103C6.77395 11.7876 6.94195 11.5023 7.20992 11.3214L11.488 12.6124C11.6862 12.6721 12.0664 12.7466 12.5115 12.6124L18.6136 10.7711C19.1287 10.6156 19.1287 9.94701 18.6136 9.79146ZM12.7172 13.2696C12.0931 13.4578 11.5613 13.3538 11.2824 13.2696L8.11008 12.3124L7.79989 14.7501C7.79989 15.5095 9.68027 16.1251 11.9999 16.1251C14.3195 16.1251 16.1999 15.5095 16.1999 14.7501L15.8897 12.3122L12.7172 13.2696Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_13771_168937' }, { children: _jsx("rect", { width: '14', height: '11', fill: 'white', transform: 'translate(5 6.5)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Education;
