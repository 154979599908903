var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Work = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: '#1FA5FF' }, void 0), _jsx("path", { d: 'M13.6973 5.01522C13.6668 5 13.6364 5 13.5908 5H10.2738C10.0152 5 9.81738 5.21301 9.81738 5.45646C9.81738 5.48689 9.81738 5.51732 9.8326 5.54775L10.1825 7H13.6821L14.0472 5.54775C14.0929 5.30431 13.9407 5.06086 13.6973 5.01522Z', fill: 'white' }, void 0), _jsx("path", { d: 'M15.0964 15.878L13.651 8.01172H10.2123L8.90379 15.878C8.87336 16.0149 8.919 16.1671 9.02551 16.2736L11.5969 18.8602C11.7643 19.0427 12.0534 19.0427 12.2359 18.8754L14.9595 16.2888C15.066 16.1823 15.1268 16.0301 15.0964 15.878Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Work;
