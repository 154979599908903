var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Engagement = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_11212_195651)' }, { children: _jsx("path", { d: 'M22 11.3333C22 10.5128 21.5503 9.80417 20.8889 9.41944V4.11146C20.8889 3.80764 20.6465 3 19.7778 3C19.5306 3 19.2851 3.08264 19.084 3.24375L16.1316 5.6059C14.6486 6.79132 12.7868 7.44444 10.8889 7.44444H4.22222C2.99479 7.44444 2 8.43924 2 9.66667V13C2 14.2274 2.99479 15.2222 4.22222 15.2222H5.39236C5.3441 15.5861 5.31667 15.9563 5.31667 16.3333C5.31667 17.7142 5.63819 19.0191 6.20417 20.1854C6.38438 20.5566 6.77778 20.7778 7.19028 20.7778H9.76944C10.674 20.7778 11.217 19.7417 10.6688 19.0222C10.0993 18.275 9.76076 17.3431 9.76076 16.3333C9.76076 15.9476 9.81701 15.5767 9.91389 15.2222H10.8889C12.7868 15.2222 14.6486 15.8753 16.1313 17.0608L19.0837 19.4229C19.2806 19.5805 19.5252 19.6664 19.7774 19.6667C20.6427 19.6667 20.8885 18.8757 20.8885 18.5556V13.2476C21.5503 12.8625 22 12.1538 22 11.3333ZM18.6667 16.2438L17.5191 15.3257C15.6441 13.8257 13.2889 13 10.8889 13V9.66667C13.2889 9.66667 15.6441 8.84097 17.5191 7.34097L18.6667 6.42292V16.2438Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_11212_195651' }, { children: _jsx("rect", { width: '20', height: '17.7778', fill: 'white', transform: 'translate(2 3)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Engagement;
