var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeTrackingFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_1485_4039)' }, { children: _jsx("path", { d: 'M16.625 13.125C16.625 15.818 14.443 18 11.75 18C9.05703 18 6.875 15.818 6.875 13.125C6.875 10.6875 8.66328 8.66719 11 8.30859V7.5H10.3438C10.1891 7.5 10.0625 7.37344 10.0625 7.21875V6.28125C10.0625 6.12656 10.1891 6 10.3438 6H13.1562C13.3109 6 13.4375 6.12656 13.4375 6.28125V7.21875C13.4375 7.37344 13.3109 7.5 13.1562 7.5H12.5V8.30859C13.3789 8.44453 14.1805 8.81484 14.8367 9.35391L15.4813 8.70937C15.5914 8.59922 15.7695 8.59922 15.8797 8.70937L16.543 9.37266C16.6531 9.48281 16.6531 9.66094 16.543 9.77109L15.8539 10.4602L15.8398 10.4742C16.3367 11.2336 16.625 12.1453 16.625 13.125ZM12.5 13.9688V10.418C12.5 10.2633 12.3734 10.1367 12.2188 10.1367H11.2812C11.1266 10.1367 11 10.2633 11 10.418V13.9688C11 14.1234 11.1266 14.25 11.2812 14.25H12.2188C12.3734 14.25 12.5 14.1234 12.5 13.9688Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_1485_4039' }, { children: _jsx("rect", { width: '10.5', height: '12', fill: 'white', transform: 'translate(6.5 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimeTrackingFilled;
