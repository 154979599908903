var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var MenuAutomation = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '22', height: '18', viewBox: '0 0 22 18', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11 1C11.5523 1 12 1.44772 12 2V5H14C16.419 5 18.4367 6.71776 18.9 9H19C20.6569 9 22 10.3431 22 12V13C22 14.6569 20.6569 16 19 16V17C19 17.5523 18.5523 18 18 18H4C3.44772 18 3 17.5523 3 17V16C1.34315 16 0 14.6569 0 13V12C0 10.3431 1.34315 9 3 9H3.10002C3.56329 6.71776 5.58104 5 8 5H10V2C10 1.44772 10.4477 1 11 1ZM3 11C2.44772 11 2 11.4477 2 12V13C2 13.5523 2.44772 14 3 14V11ZM5 16H6V13C6 12.4477 6.44772 12 7 12H15C15.5523 12 16 12.4477 16 13V16H17V10C17 8.34315 15.6569 7 14 7H8C6.34315 7 5 8.34315 5 10V16ZM19 14C19.5523 14 20 13.5523 20 13V12C20 11.4477 19.5523 11 19 11V14ZM14 16V14H12V16H14ZM10 16V14H8V16H10Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7 10C7 9.44772 7.44772 9 8 9H8.001C8.55328 9 9.001 9.44772 9.001 10C9.001 10.5523 8.55328 11 8.001 11H8C7.44772 11 7 10.5523 7 10Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13 10C13 9.44772 13.4477 9 14 9H14.001C14.5533 9 15.001 9.44772 15.001 10C15.001 10.5523 14.5533 11 14.001 11H14C13.4477 11 13 10.5523 13 10Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9 2C9 0.89543 9.89543 0 11 0C12.1046 0 13 0.89543 13 2C13 3.10457 12.1046 4 11 4C9.89543 4 9 3.10457 9 2Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default MenuAutomation;
