var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var DocumentFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M2.99999 0H15.7447L22.5 6.72902V22.5C22.5 23.3287 21.828 24 21 24H2.99999C2.17196 24 1.5 23.3287 1.5 22.5V1.49999C1.5 0.671265 2.17204 0 2.99999 0Z', fill: '#DADDE4' }, void 0), _jsx("path", { d: 'M22.4782 6.74989H17.25C16.422 6.74989 15.75 6.07793 15.75 5.2499V0.0148926L22.4782 6.74989Z', fill: '#7F8897' }, void 0), _jsx("path", { d: 'M4.5 9.75002V11.25H19.5V9.75002H4.5ZM4.5 14.2508H19.5V12.7508H4.5V14.2508ZM4.5 17.25H19.5V15.75H4.5V17.25ZM4.5 20.25H19.5V18.7507H4.5V20.25ZM12.75 6.74997H4.5V8.24996H12.75L12.75 6.74997ZM12.75 3.75H4.5V5.24999H12.75L12.75 3.75Z', fill: '#7F8897' }, void 0)] }), void 0) }), void 0));
};
export default DocumentFilled;
