var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var StarOutline = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10.5475 4.55917L9.18028 8.91687L4.54399 8.84881C4.03916 8.84127 3.56346 9.08209 3.27429 9.49158L3.19611 9.61433C2.8148 10.2816 3.0019 11.139 3.64996 11.587L7.43226 14.2018L5.9357 18.5053C5.77215 18.9755 5.85387 19.4952 6.15405 19.894L6.24712 20.0061C6.76692 20.5745 7.64826 20.6692 8.28229 20.2019L12 17.4612L15.7178 20.2019C16.1208 20.4989 16.6461 20.5798 17.1213 20.418L17.2575 20.3643C17.9628 20.047 18.3217 19.245 18.0644 18.5053L16.5668 14.2018L20.3501 11.587C20.7639 11.301 21.0073 10.8304 20.9998 10.3311L20.9906 10.1862C20.9052 9.42394 20.2465 8.837 19.4561 8.84881L14.8188 8.91687L13.4526 4.55917C13.3041 4.0857 12.9294 3.71497 12.4508 3.56803C11.6486 3.32168 10.7965 3.76544 10.5475 4.55917ZM12 6.69777L12.9956 9.87129L13.0445 10.0047C13.2818 10.5683 13.8446 10.9393 14.4711 10.9299L17.8322 10.8791L15.0924 12.774L14.9782 12.8611C14.5051 13.2589 14.3202 13.9074 14.5254 14.4973L15.6117 17.6227L12.9085 15.6299L12.7808 15.5453C12.2569 15.235 11.5892 15.2632 11.0916 15.6299L8.38738 17.6227L9.47467 14.4973L9.51514 14.3602C9.66095 13.7632 9.42451 13.1313 8.90764 12.774L6.16686 10.8791L9.52898 10.9299C10.2037 10.94 10.8045 10.509 11.0045 9.87129L12 6.69777Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default StarOutline;
