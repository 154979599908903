var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var MutedChat = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_36731_390332)' }, { children: _jsx("path", { d: 'M6.93568 3.18349L4.61875 5.49989H1.96094C1.61562 5.49989 1.33594 5.77958 1.33594 6.12489V9.87489C1.33594 10.2199 1.61562 10.4999 1.96094 10.4999H4.61875L6.93568 12.8163C7.32708 13.2077 8.0026 12.9327 8.0026 12.3744V3.62541C8.0026 3.06656 7.32656 2.7926 6.93568 3.18349ZM13.3578 7.99989L14.5464 6.81135C14.7104 6.64729 14.7104 6.38114 14.5464 6.21708L13.9521 5.62281C13.788 5.45875 13.5219 5.45875 13.3578 5.62281L12.1693 6.81135L10.9807 5.62281C10.8167 5.45875 10.5505 5.45875 10.3865 5.62281L9.79219 6.21708C9.62812 6.38114 9.62812 6.64729 9.79219 6.81135L10.9807 7.99989L9.79245 9.18817C9.62838 9.35224 9.62838 9.61838 9.79245 9.78244L10.3867 10.3767C10.5508 10.5408 10.8169 10.5408 10.981 10.3767L12.1693 9.18843L13.3578 10.377C13.5219 10.541 13.788 10.541 13.9521 10.377L14.5464 9.7827C14.7104 9.61864 14.7104 9.3525 14.5464 9.18843L13.3578 7.99989Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_36731_390332' }, { children: _jsx("rect", { width: '13.3333', height: '13.3333', fill: 'white', transform: 'translate(1.33594 1.33398)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default MutedChat;
