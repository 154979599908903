var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Lock = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 4C10.3433 4 9 5.34328 9 7V10C9 10.5523 8.55228 11 8 11C7.44772 11 7 10.5523 7 10V7C7 4.23872 9.23872 2 12 2C14.7613 2 17 4.23872 17 7V10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10V7C15 5.34328 13.6567 4 12 4Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 13C12.5523 13 13 13.4477 13 14V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V14C11 13.4477 11.4477 13 12 13Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7 11C6.44728 11 6 11.4473 6 12V19C6 19.5527 6.44728 20 7 20H17C17.5527 20 18 19.5527 18 19V12C18 11.4473 17.5527 11 17 11H7ZM4 12C4 10.3427 5.34272 9 7 9H17C18.6573 9 20 10.3427 20 12V19C20 20.6573 18.6573 22 17 22H7C5.34272 22 4 20.6573 4 19V12Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Lock;
