var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeBalancesFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_4110_122674)' }, { children: _jsx("path", { d: 'M15.9375 6H8.0625C7.75184 6 7.5 6.25184 7.5 6.5625V6.9375C7.5 7.24816 7.75184 7.5 8.0625 7.5C8.0625 9.63199 9.25819 11.4313 10.8947 12C9.25819 12.5687 8.0625 14.368 8.0625 16.5C7.75184 16.5 7.5 16.7518 7.5 17.0625V17.4375C7.5 17.7482 7.75184 18 8.0625 18H15.9375C16.2482 18 16.5 17.7482 16.5 17.4375V17.0625C16.5 16.7518 16.2482 16.5 15.9375 16.5C15.9375 14.368 14.7418 12.5687 13.1053 12C14.7418 11.4313 15.9375 9.63199 15.9375 7.5C16.2482 7.5 16.5 7.24816 16.5 6.9375V6.5625C16.5 6.25184 16.2482 6 15.9375 6ZM14.1779 15H9.82219C10.222 13.9032 11.0432 13.125 12 13.125C12.9567 13.125 13.778 13.903 14.1779 15ZM14.1783 9H9.82214C9.65597 8.54409 9.5625 8.03316 9.5625 7.5H14.4375C14.4375 8.03449 14.3441 8.54501 14.1783 9Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_4110_122674' }, { children: _jsx("rect", { width: '9', height: '12', fill: 'white', transform: 'translate(7.5 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimeBalancesFilled;
