var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var SettingsAlt = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M9.01367 3.90649C7.13567 3.90649 5.57367 5.1715 5.14267 6.9165C5.02367 6.9245 3.01367 6.90649 3.01367 6.90649C2.46167 6.90649 2.01367 7.35449 2.01367 7.90649C2.01367 8.45849 2.46167 8.90649 3.01367 8.90649C3.01367 8.90649 4.98967 8.89749 5.13467 8.90649C5.63567 10.6475 7.13567 11.9065 9.01367 11.9065C10.8707 11.9065 12.4317 10.6155 12.8827 8.90051L21.0137 8.90649C21.5657 8.90649 22.0137 8.45849 22.0137 7.90649C22.0137 7.35449 21.5657 6.90649 21.0137 6.90649L12.8957 6.90851C12.4447 5.19351 10.8707 3.90649 9.01367 3.90649ZM9.01367 5.90649C10.1187 5.90649 11.0137 6.80149 11.0137 7.90649C11.0137 9.01149 10.1187 9.90649 9.01367 9.90649C7.90867 9.90649 7.01367 9.01149 7.01367 7.90649C7.01367 6.80149 7.90867 5.90649 9.01367 5.90649ZM16.0137 11.9065C14.1567 11.9065 12.5967 13.1865 12.1457 14.9015L3.01367 14.9065C2.46167 14.9065 2.01367 15.3545 2.01367 15.9065C2.01367 16.4585 2.46167 16.9065 3.01367 16.9065L12.1497 16.9085C12.6007 18.6235 14.1567 19.9065 16.0137 19.9065C17.8707 19.9065 19.4257 18.6255 19.8767 16.9105L21.0137 16.9065C21.5657 16.9065 22.0137 16.4585 22.0137 15.9065C22.0137 15.3545 21.5657 14.9065 21.0137 14.9065L19.8857 14.9055C19.4347 13.1905 17.8707 11.9065 16.0137 11.9065ZM16.0137 13.9065C17.1187 13.9065 18.0137 14.8015 18.0137 15.9065C18.0137 17.0115 17.1187 17.9065 16.0137 17.9065C14.9087 17.9065 14.0137 17.0115 14.0137 15.9065C14.0137 14.8015 14.9087 13.9065 16.0137 13.9065Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default SettingsAlt;
