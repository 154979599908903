var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var EngagementFill = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_49193_269636)' }, { children: _jsx("path", { d: 'M18 12C18 11.5077 17.7302 11.0825 17.3333 10.8517V7.66688C17.3333 7.48458 17.1879 7 16.6667 7C16.5183 7 16.371 7.04958 16.2504 7.14625L14.479 8.56354C13.5892 9.27479 12.4721 9.66667 11.3333 9.66667H7.33333C6.59688 9.66667 6 10.2635 6 11V13C6 13.7365 6.59688 14.3333 7.33333 14.3333H8.03542C8.00646 14.5517 7.99 14.7738 7.99 15C7.99 15.8285 8.18292 16.6115 8.5225 17.3113C8.63063 17.534 8.86667 17.6667 9.11417 17.6667H10.6617C11.2044 17.6667 11.5302 17.045 11.2013 16.6133C10.8596 16.165 10.6565 15.6058 10.6565 15C10.6565 14.7685 10.6902 14.546 10.7483 14.3333H11.3333C12.4721 14.3333 13.5892 14.7252 14.4788 15.4365L16.2502 16.8538C16.3684 16.9483 16.5151 16.9999 16.6665 17C17.1856 17 17.3331 16.5254 17.3331 16.3333V13.1485C17.7302 12.9175 18 12.4923 18 12Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_49193_269636' }, { children: _jsx("rect", { width: '12', height: '10.6667', fill: 'white', transform: 'translate(6 7)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default EngagementFill;
