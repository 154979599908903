var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Headphones = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_3088:163)' }, { children: _jsx("path", { d: 'M12 6.75C8.68406 6.75 6 9.4335 6 12.75V13.875C6 14.0143 6.03878 14.1508 6.11201 14.2693C6.18523 14.3878 6.29001 14.4835 6.41459 14.5458L6.75169 14.7144C6.79882 16.1227 7.95518 17.25 9.375 17.25H9.9375C10.2482 17.25 10.5 16.9982 10.5 16.6875V12.5625C10.5 12.2518 10.2482 12 9.9375 12H9.375C8.64042 12 7.97646 12.3019 7.5 12.7881V12.75C7.5 10.2687 9.5187 8.25 12 8.25C14.4813 8.25 16.5 10.2687 16.5 12.75V12.7881C16.0235 12.3019 15.3596 12 14.625 12H14.0625C13.7518 12 13.5 12.2518 13.5 12.5625V16.6875C13.5 16.9982 13.7518 17.25 14.0625 17.25H14.625C16.0448 17.25 17.2012 16.1227 17.2483 14.7144L17.5854 14.5458C17.71 14.4835 17.8148 14.3878 17.888 14.2693C17.9612 14.1508 18 14.0143 18 13.875V12.75C18 9.43409 15.3165 6.75 12 6.75Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_3088:163' }, { children: _jsx("rect", { width: '12', height: '12', fill: 'white', transform: 'translate(6 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Headphones;
