var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from './Base';
import { Tooltip } from '../Tooltip';
import { Body } from '../Typography';
import useStyles from './styles/AvatarWithTooltipAndName';
export var AvatarWithTooltipAndName = function (_a) {
    var avatar = _a.avatar, name = _a.name, _b = _a.isNameShown, isNameShown = _b === void 0 ? false : _b, _c = _a.avatarSize, avatarSize = _c === void 0 ? 's' : _c, customId = _a.customId;
    var classes = useStyles();
    var renderNameAndCustomId = function () {
        if (isNameShown && customId) {
            return (_jsxs("div", __assign({ className: classes.nameAndCustomId }, { children: [_jsx(Body, __assign({ className: classes.name, variant: 'medium-700' }, { children: name }), void 0), _jsx(Body, __assign({ className: classes.customId, variant: 'medium' }, { children: customId }), void 0)] }), void 0));
        }
        if (isNameShown) {
            return (_jsx(Body, __assign({ className: classes.name, variant: 'medium-700' }, { children: name }), void 0));
        }
        return undefined;
    };
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(Tooltip, __assign({ title: String(name) }, { children: _jsx("div", __assign({ className: classes.avatarContainer }, { children: _jsx(Avatar, { size: avatarSize, placeHolder: name, src: avatar || '' }, void 0) }), void 0) }), void 0), renderNameAndCustomId()] }), void 0));
};
