var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Reply = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10.1539 3.07348C9.77681 2.92037 9.34451 3.01112 9.06078 3.30295L1.283 11.303C0.905664 11.6911 0.905664 12.309 1.283 12.6971L9.06078 20.6971C9.34451 20.9889 9.77681 21.0797 10.1539 20.9266C10.5311 20.7735 10.7778 20.4071 10.7778 20V16.3303C10.8347 16.3267 10.8943 16.3233 10.9564 16.3202C11.6221 16.2868 12.5711 16.2865 13.6854 16.4214C15.917 16.6914 18.7745 17.498 21.3646 19.6294C21.6634 19.8752 22.0771 19.9266 22.427 19.7614C22.7768 19.5963 23 19.2441 23 18.8572C23 16.0713 22.1666 13.9213 20.8741 12.2898C19.5937 10.6734 17.908 9.62186 16.2767 8.93545C14.6448 8.24878 13.0268 7.91024 11.8264 7.74187C11.4294 7.68618 11.0752 7.64873 10.7778 7.62351V4.00003C10.7778 3.59301 10.5311 3.22659 10.1539 3.07348ZM9.77778 9.57146L9.77996 9.57148L9.79927 9.57168C9.81773 9.57193 9.84722 9.57246 9.887 9.57358C9.96659 9.57581 10.0872 9.58039 10.2429 9.58975C10.5548 9.60849 11.0056 9.64631 11.5486 9.72248C12.6399 9.87554 14.0774 10.1799 15.501 10.7789C16.9253 11.3782 18.2952 12.2552 19.3064 13.5317C19.9842 14.3873 20.5217 15.448 20.7974 16.7775C18.3769 15.3005 15.9093 14.6759 13.9257 14.4358C12.6789 14.285 11.614 14.2847 10.8561 14.3227C10.4768 14.3418 10.173 14.3705 9.96028 14.395C9.85388 14.4073 9.77012 14.4186 9.71093 14.4272C9.68134 14.4315 9.65787 14.4351 9.64077 14.4378L9.61993 14.4411L9.61315 14.4422L9.61067 14.4427L9.60966 14.4428C9.60923 14.4429 9.60881 14.443 9.77778 15.4286L9.60881 14.443C9.12873 14.5253 8.77778 14.9415 8.77778 15.4286V17.5369L3.39471 12L8.77778 6.46316V8.57146C8.77778 9.12338 9.22491 9.57087 9.77669 9.57146C9.77682 9.57146 9.77696 9.57146 9.77709 9.57146L9.77778 9.57146Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Reply;
