var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { Input } from '../Input';
import { FormControl } from '../FormControl/FormControl';
var useStyles = makeStyles(function (theme) { return ({
    active: {
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}); });
export var TimePicker = function (_a) {
    var _b;
    var inputLocale = _a.inputLocale, onChange = _a.onChange, value = _a.value, required = _a.required, id = _a.id, hiddenLabel = _a.hiddenLabel, _c = _a.hasError, hasError = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.minutesStep, minutesStep = _e === void 0 ? 5 : _e, ariaLabel = _a.ariaLabel, className = _a.className, format = _a.format, showSeconds = _a.showSeconds;
    var classes = useStyles();
    var _f = __read(useState(false), 2), isActive = _f[0], setIsActive = _f[1];
    var renderInput = useCallback(function (props) {
        var _a;
        return (_jsx(Input, { inputRef: props.inputRef, inputProps: props.inputProps, value: props.value, onClick: function (e) {
                var _a;
                (_a = props === null || props === void 0 ? void 0 : props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
                setIsActive(true);
            }, onChange: props.onChange, disabled: disabled, onBlur: function () {
                setIsActive(false);
            }, required: required, hiddenLabel: true, id: id, locale: {
                placeholder: inputLocale === null || inputLocale === void 0 ? void 0 : inputLocale.placeholder,
            }, error: props.error || hasError || (required && props.value === null), endAdornment: (_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.endAdornment }, void 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        );
    }, [disabled]);
    return (_jsx("div", __assign({ className: className }, { children: _jsx(FormControl, __assign({ active: isActive, error: hasError, errorMessage: inputLocale === null || inputLocale === void 0 ? void 0 : inputLocale.errorMessage, label: inputLocale === null || inputLocale === void 0 ? void 0 : inputLocale.label, hiddenLabel: hiddenLabel }, { children: _jsx(MuiPickersUtilsProvider, __assign({ utils: DateFnsUtils }, { children: _jsx(KeyboardTimePicker, { clearable: true, "aria-label": ariaLabel, variant: 'inline', margin: 'dense', id: 'time-picker', label: 'From', inputVariant: 'filled', autoOk: true, views: showSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes'], disabled: disabled, InputProps: { readOnly: disabled }, minutesStep: minutesStep, format: showSeconds ? 'HH:mm:ss' : format, KeyboardButtonProps: {
                        onFocus: function () { return setIsActive(true); },
                        onBlur: function () { return setIsActive(false); },
                        'aria-label': 'change time',
                    }, keyboardIcon: (_jsx(Icons.Time, { className: cx((_b = {},
                            _b[classes.active] = isActive,
                            _b[classes.error] = hasError,
                            _b)) }, void 0)), value: value, TextFieldComponent: renderInput, onChange: function (e) {
                        if (e && !isNaN(e.getTime()) && !showSeconds) {
                            onChange(new Date(e.setSeconds(0)));
                        }
                        else {
                            onChange(e);
                        }
                    }, ampm: false }, void 0) }), void 0) }), void 0) }), void 0));
};
