var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Cash = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_54669_104357)' }, { children: _jsx("path", { d: 'M6.75 7.5C5.92266 7.5 5.25 8.17266 5.25 9V15C5.25 15.8273 5.92266 16.5 6.75 16.5H17.25C18.0773 16.5 18.75 15.8273 18.75 15V9C18.75 8.17266 18.0773 7.5 17.25 7.5H6.75ZM8.25 15H6.75V13.5C7.57734 13.5 8.25 14.1727 8.25 15ZM6.75 10.5V9H8.25C8.25 9.82734 7.57734 10.5 6.75 10.5ZM15.75 15C15.75 14.1727 16.4227 13.5 17.25 13.5V15H15.75ZM17.25 10.5C16.4227 10.5 15.75 9.82734 15.75 9H17.25V10.5ZM12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_54669_104357' }, { children: _jsx("rect", { width: '13.5', height: '12', fill: 'white', transform: 'translate(5.25 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Cash;
