var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var EventFilled = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_29808_174224)' }, { children: _jsx("path", { d: 'M12.7143 2.9987H11.4286V1.7487C11.4286 1.51953 11.2357 1.33203 11 1.33203H10.1429C9.90714 1.33203 9.71429 1.51953 9.71429 1.7487V2.9987H6.28571V1.7487C6.28571 1.51953 6.09286 1.33203 5.85714 1.33203H5C4.76429 1.33203 4.57143 1.51953 4.57143 1.7487V2.9987H3.28571C2.57589 2.9987 2 3.55859 2 4.2487V5.4987H14V4.2487C14 3.55859 13.4241 2.9987 12.7143 2.9987ZM2 13.4154C2 14.1055 2.57589 14.6654 3.28571 14.6654H12.7143C13.4241 14.6654 14 14.1055 14 13.4154V6.33203H2V13.4154ZM5.59196 9.67318L7.05714 9.46484L7.71339 8.17318C7.83125 7.9388 8.17143 7.94401 8.28929 8.17318L8.94554 9.46484L10.4107 9.67318C10.6732 9.70964 10.7777 10.0247 10.5875 10.207L9.52679 11.2122L9.77857 12.6341C9.82411 12.8919 9.54554 13.082 9.3125 12.9622L8 12.2904L6.69018 12.9622C6.45714 13.082 6.17857 12.8893 6.22411 12.6341L6.47589 11.2122L5.41518 10.207C5.22232 10.0247 5.32946 9.70964 5.59196 9.67318Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_29808_174224' }, { children: _jsx("rect", { width: '12', height: '13.3333', fill: 'white', transform: 'translate(2 1.33203)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default EventFilled;
