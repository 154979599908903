var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var MartiniGlassCitrusSolid = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_29135_34276)' }, { children: _jsx("path", { d: 'M12 16.875H10.875V13.9383L14.8313 9.98438C15.1945 9.62109 14.9367 9 14.4235 9H5.82728C5.31449 9 5.05668 9.62109 5.41996 9.98438L9.37387 13.9383V16.875H8.25002C7.83588 16.875 7.50002 17.2109 7.50002 17.625C7.50002 17.8321 7.66792 18 7.85392 18H12.3328C12.5399 18 12.6867 17.8321 12.6867 17.625C12.75 17.2102 12.4149 16.875 12 16.875ZM15.375 6C13.9071 6 12.6703 6.94336 12.2086 8.25H13.4391C13.8286 7.58203 14.5465 7.125 15.3755 7.125C16.6149 7.125 17.6044 8.13281 17.6044 9.375C17.6044 10.6172 16.5938 11.625 15.3755 11.625C15.0474 11.625 14.7368 11.5488 14.4528 11.4229L13.6266 12.249C14.1394 12.5596 14.734 12.7501 15.3755 12.7501C17.2388 12.7501 18.7294 11.2384 18.7294 9.37512C18.7294 7.51184 17.2383 6 15.375 6Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_29135_34276' }, { children: _jsx("rect", { width: '13.5', height: '12', fill: 'white', transform: 'translate(5.25 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default MartiniGlassCitrusSolid;
