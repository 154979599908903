var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect, } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import cx from 'classnames';
import { getDateFnsLocaleFromJobDoneLocale, getSundayOfWeek, getMondayOfWeek, JobDoneLocales, getSundayOfNextWeek, getEndOfMonth, getEndOfDay, getStartOfDay, getStartOfMonth, getStartOfYear, getEndOfYear, getMaxDate, } from '../../../utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from '../styles/RangePicker.styles';
import Input from './Input';
import { ChoiceTag } from '../../ChoiceTag';
import { rangePickerVariantEnum } from '../../../utils/consts';
export var RangePicker = function (_a) {
    var _b;
    var _c = _a.defaultRangePickerVariantType, defaultRangePickerVariantType = _c === void 0 ? rangePickerVariantEnum['1_WEEK'] : _c, _d = _a.chosenDatePeriod, chosenDatePeriod = _d === void 0 ? {
        from: getMondayOfWeek(getStartOfDay(new Date())),
        to: getSundayOfWeek(getEndOfDay(new Date())),
    } : _d, setChosenDatePeriod = _a.setChosenDatePeriod, _e = _a.locale, locale = _e === void 0 ? JobDoneLocales.ENGLISH : _e, _f = _a.rangeLocale, rangeLocale = _f === void 0 ? {
        TODAY: '1 Day',
        '1_WEEK': '1 Week',
        '2_WEEK': '2 Weeks',
        '1_MONTH': '1 Month',
        '1_YEAR': '1 Year',
        '1_QUARTER': '1 Quarter',
        CUSTOM: 'Custom',
    } : _f, hideSidebar = _a.hideSidebar, _g = _a.hideRangeButtons, hideRangeButtons = _g === void 0 ? [] : _g, inline = _a.inline, getRangePickerType = _a.getRangePickerType, rest = __rest(_a, ["defaultRangePickerVariantType", "chosenDatePeriod", "setChosenDatePeriod", "locale", "rangeLocale", "hideSidebar", "hideRangeButtons", "inline", "getRangePickerType"]);
    var classes = useStyles({ inline: inline });
    var ref = useRef(null);
    var _h = __read(useState(defaultRangePickerVariantType), 2), rangePickerVariant = _h[0], setRangePickerVariant = _h[1];
    var isOneDaySelected = rangePickerVariant === rangePickerVariantEnum.TODAY;
    var isOneWeekSelected = rangePickerVariant === rangePickerVariantEnum['1_WEEK'];
    var isTwoWeekSelected = rangePickerVariant === rangePickerVariantEnum['2_WEEK'];
    var isOneMonthSelected = rangePickerVariant === rangePickerVariantEnum['1_MONTH'];
    var isOneYearSelected = rangePickerVariant === rangePickerVariantEnum['1_YEAR'];
    var isCustomSelected = rangePickerVariant === rangePickerVariantEnum.CUSTOM;
    useEffect(function () {
        var _a, _b;
        if (!isCustomSelected && (((_a = chosenDatePeriod.from) === null || _a === void 0 ? void 0 : _a.getDate()) === ((_b = chosenDatePeriod.to) === null || _b === void 0 ? void 0 : _b.getDate()))) {
            setRangePickerVariant(rangePickerVariantEnum.TODAY);
        }
    }, [chosenDatePeriod, isCustomSelected]);
    useEffect(function () {
        getRangePickerType && getRangePickerType(rangePickerVariant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangePickerVariant]);
    var getFromDate = function (date) {
        if (isOneDaySelected) {
            return getStartOfDay(date);
        }
        if (isOneMonthSelected) {
            return getStartOfMonth(date);
        }
        if (isOneYearSelected) {
            return getStartOfYear(date);
        }
        return getStartOfDay(getMondayOfWeek(date));
    };
    var getToDate = function (date) {
        if (isOneWeekSelected) {
            return getEndOfDay(getSundayOfWeek(getMondayOfWeek(date)));
        }
        if (isTwoWeekSelected) {
            return getEndOfDay(getSundayOfNextWeek(getMondayOfWeek(date)));
        }
        if (isOneMonthSelected) {
            return getEndOfMonth(date);
        }
        if (isOneYearSelected) {
            return getEndOfYear(date);
        }
        return getEndOfDay(date);
    };
    var handleDateChange = function (chosenDate) {
        var _a;
        if (Array.isArray(chosenDate)) {
            if (isCustomSelected) {
                var _b = __read(chosenDate, 2), start = _b[0], end = _b[1];
                var updatedPeriod = {
                    from: start ? getStartOfDay(start) : null,
                    to: end ? getEndOfDay(end) : null,
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
            }
            else {
                var _c = __read(chosenDate, 1), start = _c[0];
                var updatedPeriod = {
                    from: getFromDate(start),
                    to: getToDate(start),
                };
                setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
            }
        }
        else {
            var updatedPeriod = {
                from: getFromDate(chosenDate),
                to: getToDate(chosenDate),
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        !isCustomSelected && ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.setOpen(false));
    };
    var handleRangePickerChange = function (shownRangePickerVariant) {
        if (shownRangePickerVariant !== rangePickerVariantEnum['1_QUARTER']) {
            setRangePickerVariant(function (prev) {
                if (prev === shownRangePickerVariant && !(hideRangeButtons === null || hideRangeButtons === void 0 ? void 0 : hideRangeButtons.includes(rangePickerVariantEnum.TODAY))) {
                    return rangePickerVariantEnum.TODAY;
                }
                return shownRangePickerVariant;
            });
        }
    };
    useEffect(function () {
        if (isOneDaySelected) {
            var updatedPeriod = {
                from: getStartOfDay(chosenDatePeriod.from),
                to: getEndOfDay(chosenDatePeriod.from),
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        else if (isOneMonthSelected) {
            var updatedPeriod = {
                from: getStartOfMonth(chosenDatePeriod.from),
                to: getEndOfMonth(chosenDatePeriod.from),
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        else if (isOneYearSelected) {
            var updatedPeriod = {
                from: getStartOfYear(chosenDatePeriod.from),
                to: getEndOfYear(chosenDatePeriod.from),
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        else if (isCustomSelected) {
            var updatedPeriod = {
                from: chosenDatePeriod.from,
                to: chosenDatePeriod.to,
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        else {
            var updatedPeriod = {
                from: getStartOfDay(getMondayOfWeek(chosenDatePeriod.from)),
                to: getToDate(chosenDatePeriod.from),
            };
            setChosenDatePeriod === null || setChosenDatePeriod === void 0 ? void 0 : setChosenDatePeriod(updatedPeriod);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangePickerVariant]);
    var CalendarContainerComponent = function (_a) {
        var _b, _c;
        var children = _a.children, props = __rest(_a, ["children"]);
        if (document) {
            if (rangePickerVariant !== rangePickerVariantEnum.TODAY && rangePickerVariant !== rangePickerVariantEnum.CUSTOM) {
                var monthContainer = document.querySelector('.react-datepicker__month');
                monthContainer === null || monthContainer === void 0 ? void 0 : monthContainer.childNodes.forEach(function (element) {
                    if (element.classList.contains(classes.selectedWeek)) {
                        element.classList.remove(classes.selectedWeek);
                    }
                });
                var dayRangeStart = document.querySelector('.react-datepicker__day--range-start');
                var dayRangeEnd = document.querySelector('.react-datepicker__day--range-end');
                (_b = dayRangeStart === null || dayRangeStart === void 0 ? void 0 : dayRangeStart.parentElement) === null || _b === void 0 ? void 0 : _b.classList.add(classes.selectedWeek);
                (_c = dayRangeEnd === null || dayRangeEnd === void 0 ? void 0 : dayRangeEnd.parentElement) === null || _c === void 0 ? void 0 : _c.classList.add(classes.selectedWeek);
            }
        }
        return (_jsxs(CalendarContainer, __assign({}, props, { children: [children, !hideSidebar && (_jsx("div", __assign({ className: classes.rangeTypes }, { children: Object.entries(rangeLocale).map(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], value = _b[1];
                        if (hideRangeButtons === null || hideRangeButtons === void 0 ? void 0 : hideRangeButtons.includes(key)) {
                            return null;
                        }
                        return (_jsx(ChoiceTag, __assign({ selected: key === rangePickerVariant, onClick: function () { return handleRangePickerChange(key); } }, { children: value }), key));
                    }) }), void 0))] }), void 0));
    };
    var renderInputDate = function () {
        if (isOneDaySelected) {
            return 'd MMM, yyyy';
        }
        if (isOneMonthSelected) {
            return 'MMMM yyyy';
        }
        if (isOneYearSelected) {
            return 'yyyy';
        }
        return 'd MMM';
    };
    return (_jsx(DatePicker, __assign({}, rest, { inline: inline, startDate: chosenDatePeriod.from, endDate: chosenDatePeriod.to, onChange: handleDateChange, calendarClassName: cx(classes.calendarRoot, (_b = {},
            _b[classes.oneWeek] = isOneWeekSelected,
            _b[classes.twoWeek] = isTwoWeekSelected,
            _b[classes.custom] = isCustomSelected,
            _b)), selected: !isCustomSelected ? chosenDatePeriod.from : undefined, selectsRange: isOneWeekSelected || isTwoWeekSelected || isCustomSelected, locale: getDateFnsLocaleFromJobDoneLocale(locale), showMonthYearPicker: isOneMonthSelected, showYearPicker: isOneYearSelected, dateFormat: renderInputDate(), calendarStartDay: 1, customInput: (_jsx(Input, { setChosenDatePeriod: setChosenDatePeriod, chosenDatePeriod: chosenDatePeriod, isOneWeekSelected: isOneWeekSelected, isTwoWeekSelected: isTwoWeekSelected, isOneMonthSelected: isOneMonthSelected, isOneDaySelected: isOneDaySelected, isOneYearSelected: isOneYearSelected, isCustomSelected: isCustomSelected }, void 0)), calendarContainer: CalendarContainerComponent, ref: ref, maxDate: !chosenDatePeriod.to ? getMaxDate(chosenDatePeriod.from) : undefined }), void 0));
};
