var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var FolderEmployees = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 4C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V11.5C22 9.84315 20.6569 8.5 19 8.5H17.0704C16.736 8.5 16.4238 8.3329 16.2383 8.0547L14.4258 5.3359C13.8694 4.5013 12.9327 4 11.9296 4H5ZM11.8125 9.8125C11.8125 11.3652 10.5527 12.625 9 12.625C7.44727 12.625 6.1875 11.3652 6.1875 9.8125C6.1875 8.25977 7.44727 7 9 7C10.5527 7 11.8125 8.25977 11.8125 9.8125ZM10.4238 13.25H11.5C12.8809 13.25 14 14.3691 14 15.75V16.0625C14 16.5801 13.5801 17 13.0625 17H4.9375C4.41992 17 4 16.5801 4 16.0625V15.75C4 14.3691 5.11914 13.25 6.5 13.25H7.57617C8.01172 13.4492 8.49219 13.5625 9 13.5625C9.50781 13.5625 9.99023 13.4492 10.4238 13.25Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default FolderEmployees;
