var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeBalanceActual = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_17574_239317)' }, { children: _jsx("path", { d: 'M16.8125 5.25H14C14 4.00898 12.991 3 11.75 3C10.509 3 9.5 4.00898 9.5 5.25H6.6875C5.75586 5.25 5 6.00586 5 6.9375V19.3125C5 20.2441 5.75586 21 6.6875 21H16.8125C17.7441 21 18.5 20.2441 18.5 19.3125V6.9375C18.5 6.00586 17.7441 5.25 16.8125 5.25ZM11.75 4.40625C12.2176 4.40625 12.5938 4.78242 12.5938 5.25C12.5938 5.71758 12.2176 6.09375 11.75 6.09375C11.2824 6.09375 10.9062 5.71758 10.9062 5.25C10.9062 4.78242 11.2824 4.40625 11.75 4.40625ZM7.30625 10.3617L7.75273 9.91875C7.82656 9.84492 7.94609 9.84492 8.01992 9.91875L8.74414 10.65L10.4176 8.99062C10.4914 8.9168 10.6109 8.9168 10.6848 8.99062L11.1277 9.43711C11.2016 9.51094 11.2016 9.63047 11.1277 9.7043L8.8707 11.9402C8.79688 12.0141 8.67734 12.0141 8.60352 11.9402L7.30625 10.6289C7.23242 10.5551 7.23242 10.4355 7.30625 10.3617ZM8.375 16.7812C7.90742 16.7812 7.53125 16.4051 7.53125 15.9375C7.53125 15.4699 7.90742 15.0938 8.375 15.0938C8.84258 15.0938 9.21875 15.4699 9.21875 15.9375C9.21875 16.4051 8.84258 16.7812 8.375 16.7812ZM16.25 16.2188C16.25 16.3734 16.1234 16.5 15.9688 16.5H10.9062C10.7516 16.5 10.625 16.3734 10.625 16.2188V15.6562C10.625 15.5016 10.7516 15.375 10.9062 15.375H15.9688C16.1234 15.375 16.25 15.5016 16.25 15.6562V16.2188ZM16.25 11.7188C16.25 11.8734 16.0988 12 15.9125 12H10.4176L11.5531 10.875H15.9125C16.0988 10.875 16.25 11.0016 16.25 11.1562V11.7188Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_17574_239317' }, { children: _jsx("rect", { width: '13.5', height: '18', fill: 'white', transform: 'translate(5 3)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default TimeBalanceActual;
