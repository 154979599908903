var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ScheduleFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_19218_234539)' }, { children: _jsx("path", { d: 'M7.28125 10.5H17.2188C17.3734 10.5 17.5 10.6266 17.5 10.7812V16.875C17.5 17.4961 16.9961 18 16.375 18H8.125C7.50391 18 7 17.4961 7 16.875V10.7812C7 10.6266 7.12656 10.5 7.28125 10.5ZM17.5 9.46875V8.625C17.5 8.00391 16.9961 7.5 16.375 7.5H15.25V6.28125C15.25 6.12656 15.1234 6 14.9688 6H14.0312C13.8766 6 13.75 6.12656 13.75 6.28125V7.5H10.75V6.28125C10.75 6.12656 10.6234 6 10.4688 6H9.53125C9.37656 6 9.25 6.12656 9.25 6.28125V7.5H8.125C7.50391 7.5 7 8.00391 7 8.625V9.46875C7 9.62344 7.12656 9.75 7.28125 9.75H17.2188C17.3734 9.75 17.5 9.62344 17.5 9.46875Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_19218_234539' }, { children: _jsx("rect", { width: '10.5', height: '12', fill: 'white', transform: 'translate(7 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default ScheduleFilled;
