var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Attach = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7V14C15 15.6569 13.6569 17 12 17C10.3431 17 9 15.6569 9 14V9C9 8.44772 9.44772 8 10 8C10.5523 8 11 8.44772 11 9V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V7C13 5.34315 11.6569 4 10 4C8.34315 4 7 5.34315 7 7V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V5C17 4.44772 17.4477 4 18 4C18.5523 4 19 4.44772 19 5V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V7Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Attach;
