var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as MUIAvatar, Badge, } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { Tooltip } from '../Tooltip';
import useStyles from './styles/Avatar.styles';
import { getPlaceHolderText, SIZES, TYPES, } from './utils';
export function Avatar(props) {
    var _a = props.size, size = _a === void 0 ? SIZES.m : _a, _b = props.type, type = _b === void 0 ? TYPES.default : _b, badge = props.badge, className = props.className, containerClassName = props.containerClassName, children = props.children, placeHolder = props.placeHolder, _c = props.showToolTip, showToolTip = _c === void 0 ? false : _c, borderColor = props.borderColor, rest = __rest(props, ["size", "type", "badge", "className", "containerClassName", "children", "placeHolder", "showToolTip", "borderColor"]);
    var styles = useStyles({ borderColor: borderColor });
    var classes = cx(styles.root, styles[size], className, styles.borderStyle);
    var renderContent = function () {
        var defaultContent = (_jsx("div", __assign({ className: cx(styles[type], containerClassName) }, { children: (showToolTip && placeHolder) ? (_jsx(Tooltip, __assign({ title: placeHolder }, { children: _jsx(MUIAvatar, __assign({ className: classes }, rest, { children: placeHolder ? getPlaceHolderText(placeHolder) : children }), void 0) }), void 0)) : (_jsx(MUIAvatar, __assign({ className: classes }, rest, { children: placeHolder ? getPlaceHolderText(placeHolder) : children }), void 0)) }), void 0));
        var getBadgeContent = function () {
            if (badge === 'approved')
                return _jsx(Icons.ApprovedCircleCheck, {}, void 0);
            if (badge === 'awaitingApproval')
                return _jsx(Icons.AwaitingApproval, {}, void 0);
            if (badge === 'folder')
                return _jsx(Icons.FolderAvatarVersion, { className: styles.folderIcon }, void 0);
            return undefined;
        };
        if (badge) {
            return (_jsx(Badge, __assign({ overlap: 'circular', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, variant: (badge === 'approved' || badge === 'awaitingApproval' || badge === 'folder') ? 'standard' : 'dot', classes: {
                    badge: cx(styles.badge, styles["badge_" + badge]),
                }, badgeContent: getBadgeContent() }, { children: defaultContent }), void 0));
        }
        return defaultContent;
    };
    return renderContent();
}
