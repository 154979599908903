var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './styles';
var Pinned = function (props) { return (_jsx("span", __assign({ className: useStyles().jobDoneIcon }, props, { children: _jsxs("svg", __assign({ width: '12', height: '12', viewBox: '0 0 12 12', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ clipPath: 'url(#clip0_44309_363536)' }, { children: _jsx("path", { d: 'M7.65679 7.57513L9.12117 5.77281L9.70407 6.35572C9.88713 6.53878 10.1839 6.53878 10.367 6.35572L11.0299 5.69281C11.213 5.50975 11.213 5.21296 11.0299 5.0299L7.2734 1.2734C7.09034 1.09034 6.79355 1.09034 6.61049 1.2734L5.94757 1.93631C5.76451 2.11937 5.76451 2.41616 5.94757 2.59922L6.53048 3.18213L4.72816 4.6465C3.74678 4.28806 2.67084 4.32916 1.9701 5.0299C1.78704 5.21296 1.78704 5.50975 1.9701 5.69281L3.84835 7.57106L2.41195 9.00746C2.39479 9.02462 2.38187 9.04553 2.3742 9.06855L2.04275 10.0629C2.00213 10.1848 2.1183 10.3012 2.24039 10.2606L3.23476 9.9291C3.25777 9.92143 3.27868 9.90851 3.29584 9.89136L4.73225 8.45495L6.6105 10.3332C6.79356 10.5163 7.09035 10.5163 7.27341 10.3332C7.98027 9.62632 8.01141 8.54606 7.65679 7.57513Z', fill: 'currentColor' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_44309_363536' }, { children: _jsx("rect", { width: '12', height: '12', fill: 'white' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0)); };
export default Pinned;
