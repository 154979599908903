var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var LineGraph = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M17.625 15H7.5V7.875C7.5 7.66781 7.33219 7.5 7.125 7.5H6.375C6.16781 7.5 6 7.66781 6 7.875V15.75C6 16.1641 6.33586 16.5 6.75 16.5H17.625C17.8322 16.5 18 16.3322 18 16.125V15.375C18 15.1678 17.8322 15 17.625 15ZM16.875 8.25H14.108C13.6069 8.25 13.3559 8.85586 13.7102 9.21023L14.4696 9.96961L12.75 11.6895L11.0304 9.96984C10.7374 9.67688 10.2626 9.67688 9.96984 9.96984L8.35992 11.5798C8.21344 11.7263 8.21344 11.9637 8.35992 12.1102L8.89008 12.6403C9.03656 12.7868 9.27398 12.7868 9.42047 12.6403L10.5 11.5605L12.2196 13.2802C12.5126 13.5731 12.9874 13.5731 13.2802 13.2802L15.5302 11.0302L16.2895 11.7895C16.6439 12.1439 17.2498 11.8929 17.2498 11.3918V8.625C17.25 8.41781 17.0822 8.25 16.875 8.25Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default LineGraph;
