var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Timer = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H13V5.05493C14.4434 5.21449 15.784 5.71541 16.9391 6.47517L17.7071 5.7072C18.0976 5.31668 18.7308 5.31668 19.1213 5.7072C19.5118 6.09772 19.5118 6.73089 19.1213 7.12141L18.4842 7.75856C20.0421 9.37665 21 11.5765 21 14C21 18.9706 16.9706 23 12 23C7.02944 23 3 18.9706 3 14C3 9.36745 6.50005 5.55237 11 5.05493V4H10C9.44772 4 9 3.55228 9 3ZM13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10V14C11 14.5523 11.4477 15 12 15H15C15.5523 15 16 14.5523 16 14C16 13.4477 15.5523 13 15 13H13V10Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Timer;
