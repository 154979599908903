var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Generate = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M19.4614 9.53469L19.5549 9.61788L22.2094 12.2929L22.2919 12.3871C22.5694 12.7467 22.5694 13.2533 22.2919 13.6129L22.2094 13.7071L22.1159 13.7903C21.759 14.0699 21.2563 14.0699 20.8995 13.7903L20.806 13.7071L19.882 12.775C19.7726 13.9193 19.422 15.0151 18.8561 16.0008C17.4471 18.4553 14.8467 20 11.9803 20C9.5101 20 7.22546 18.8544 5.73039 16.9332C5.39219 16.4986 5.46763 15.8701 5.89887 15.5292C6.33012 15.1884 6.95388 15.2644 7.29207 15.699C8.41484 17.1418 10.1263 18 11.9803 18C14.131 18 16.0805 16.842 17.1382 14.9994C17.5414 14.2969 17.7986 13.5208 17.8932 12.708L16.9004 13.7071L16.8069 13.7903C16.4176 14.0953 15.8547 14.0676 15.497 13.7071C15.1393 13.3466 15.1118 12.7794 15.4145 12.3871L15.497 12.2929L18.1515 9.61788L18.245 9.53469C18.6018 9.25508 19.1045 9.25508 19.4614 9.53469ZM12.0682 4C14.5383 4 16.823 5.1456 18.3181 7.06677C18.6563 7.50135 18.5808 8.12994 18.1496 8.47075C17.7183 8.81157 17.0946 8.73555 16.7564 8.30097C15.6336 6.8582 13.9221 6 12.0682 6C9.91745 6 7.96799 7.158 6.91023 9.00064C6.65874 9.43875 6.46407 9.90552 6.3302 10.392L7.09962 9.61788L7.1931 9.53469C7.58238 9.22967 8.14525 9.2574 8.50297 9.61788C8.86068 9.97836 8.8882 10.5456 8.58552 10.9379L8.50297 11.0321L5.84848 13.7071L5.755 13.7903C5.39816 14.0699 4.89545 14.0699 4.53861 13.7903L4.44513 13.7071L1.79064 11.0321L1.70809 10.9379C1.43064 10.5783 1.43064 10.0717 1.70809 9.71209L1.79064 9.61788L1.88413 9.53469C2.24097 9.25508 2.74367 9.25508 3.10051 9.53469L3.19399 9.61788L4.2388 10.6712C4.39548 9.72742 4.71843 8.82471 5.19233 7.99916C6.60132 5.54469 9.20174 4 12.0682 4Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Generate;
