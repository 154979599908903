var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var WishesFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: '#1FA5FF' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M10.1093 6.91169C10.2908 6.36724 10.8003 6 11.3742 6H12.227C12.9634 6 13.5603 6.59695 13.5603 7.33332V9.99995H17.1483C18.1394 9.99995 18.7841 11.043 18.3408 11.9295L15.6742 17.2628C15.4483 17.7145 14.9867 17.9999 14.4816 17.9999H9.97241C9.46738 17.9999 9.0057 17.7145 8.77985 17.2628L7.75429 15.2117C7.59614 14.8954 7.57012 14.5292 7.68195 14.1938L10.1093 6.91169Z', fill: 'white' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M7.10457 10.0342C7.45386 10.1506 7.64263 10.5281 7.5262 10.8774L6.36753 14.3534C6.31162 14.5212 6.32463 14.7043 6.4037 14.8624L7.49003 17.0351C7.65469 17.3644 7.52121 17.7648 7.19189 17.9295C6.86258 18.0941 6.46213 17.9606 6.29748 17.6313L5.21114 15.4587C4.97393 14.9842 4.9349 14.435 5.10263 13.9318L6.26131 10.4558C6.37774 10.1065 6.75528 9.91773 7.10457 10.0342Z', fill: 'white' }, void 0)] }), void 0) }), void 0) }), void 0));
};
export default WishesFilled;
