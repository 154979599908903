var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, forwardRef, useRef, } from 'react';
import { Checkbox } from '../Checkbox';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var useCombinedRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    var targetRef = useRef();
    useEffect(function () {
        refs.forEach(function (ref) {
            if (!ref)
                return;
            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            else {
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [refs]);
    return targetRef;
};
var IndeterminateCheckbox = forwardRef(
// eslint-disable-next-line react/prop-types
function (_a, ref) {
    var indeterminate = _a.indeterminate, rest = __rest(_a, ["indeterminate"]);
    var defaultRef = useRef(null);
    var combinedRef = useCombinedRefs(ref, defaultRef);
    return (_jsx(Checkbox
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    , __assign({ 
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        onClick: function (e) { return e === null || e === void 0 ? void 0 : e.stopPropagation(); }, indeterminate: indeterminate, ref: combinedRef }, rest), void 0));
});
export default IndeterminateCheckbox;
