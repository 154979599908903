var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Resend = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M19.5 3.59961C20.0523 3.59961 20.5 4.04732 20.5 4.59961V8.19961C20.5 8.75189 20.0523 9.19961 19.5 9.19961H16C15.4477 9.19961 15 8.75189 15 8.19961C15 7.64732 15.4477 7.19961 16 7.19961H18.5V4.59961C18.5 4.04732 18.9477 3.59961 19.5 3.59961Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M3 12C3 7.04772 7.04772 3 12 3C15.3743 3 18.4157 4.9088 19.8872 7.73865C20.142 8.22864 19.9514 8.83242 19.4614 9.08722C18.9714 9.34201 18.3676 9.15135 18.1128 8.66135C16.9843 6.4912 14.6257 5 12 5C8.15228 5 5 8.15228 5 12C5 15.8477 8.15228 19 12 19C15.8477 19 19 15.8477 19 12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12C21 16.9523 16.9523 21 12 21C7.04772 21 3 16.9523 3 12Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Resend;
