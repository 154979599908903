var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var ShiftBlocked = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '20', height: '20', viewBox: '0 0 20 20', fill: 'currentColor', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M12.5798 1.66699H7.4195C4.24704 1.66699 1.66602 4.2477 1.66602 7.41988V12.5808C1.66602 15.7529 4.24704 18.3337 7.4195 18.3337H12.5798C15.7519 18.3337 18.3327 15.7529 18.3327 12.5808V7.41988C18.3327 4.24774 15.7519 1.66699 12.5798 1.66699V1.66699Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M13.2895 7.01046C13.2331 6.87471 13.1253 6.76683 12.9895 6.71046C12.9227 6.68199 12.851 6.66689 12.7784 6.66602H7.22284C7.0755 6.66602 6.93419 6.72455 6.83 6.82873C6.72582 6.93292 6.66728 7.07423 6.66728 7.22157C6.66728 7.36891 6.72582 7.51022 6.83 7.61441C6.93419 7.71859 7.0755 7.77713 7.22284 7.77713H11.4395L6.82839 12.3827C6.77632 12.4343 6.73499 12.4958 6.70679 12.5635C6.67858 12.6312 6.66406 12.7038 6.66406 12.7771C6.66406 12.8505 6.67858 12.9231 6.70679 12.9908C6.73499 13.0585 6.77632 13.1199 6.82839 13.1716C6.88004 13.2236 6.94149 13.265 7.00919 13.2932C7.07689 13.3214 7.1495 13.3359 7.22284 13.3359C7.29618 13.3359 7.36879 13.3214 7.43649 13.2932C7.50419 13.265 7.56564 13.2236 7.61728 13.1716L12.2228 8.56046V12.7771C12.2228 12.9245 12.2814 13.0658 12.3856 13.17C12.4897 13.2742 12.6311 13.3327 12.7784 13.3327C12.9257 13.3327 13.067 13.2742 13.1712 13.17C13.2754 13.0658 13.334 12.9245 13.334 12.7771V7.22157C13.3331 7.14897 13.318 7.07725 13.2895 7.01046V7.01046Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default ShiftBlocked;
