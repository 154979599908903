var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var PDF = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M2.99999 0H15.7447L22.5 6.72902V22.5C22.5 23.3287 21.828 24 21 24H2.99999C2.17196 24 1.5 23.3287 1.5 22.5V1.49999C1.5 0.671265 2.17204 0 2.99999 0Z', fill: '#206AE9' }, void 0), _jsx("path", { d: 'M22.4783 6.74965H17.25C16.422 6.74965 15.75 6.07769 15.75 5.24966V0.0146484L22.4783 6.74965Z', fill: '#0F4095' }, void 0), _jsx("path", { d: 'M17.5719 11.4963C17.8593 11.4963 18 11.2458 18 11.003C18 10.7516 17.8533 10.5088 17.5719 10.5088H15.9349C15.6149 10.5088 15.4365 10.7739 15.4365 11.0664V15.0893C15.4365 15.4479 15.6406 15.647 15.9169 15.647C16.1915 15.647 16.3965 15.4479 16.3965 15.0893V13.9852H17.3866C17.6937 13.9852 17.8473 13.7338 17.8473 13.4841C17.8473 13.2397 17.6937 12.9968 17.3866 12.9968H16.3965V11.4963H17.5719ZM12.039 10.5088H10.8413C10.5161 10.5088 10.2853 10.7319 10.2853 11.063V15.0928C10.2853 15.5037 10.6173 15.6324 10.855 15.6324H12.1119C13.5995 15.6324 14.5819 14.6535 14.5819 13.1426C14.5811 11.5452 13.6562 10.5088 12.039 10.5088ZM12.0965 14.639H11.3664V11.5023H12.0245C13.0206 11.5023 13.4538 12.1707 13.4538 13.0929C13.4538 13.956 13.0282 14.639 12.0965 14.639ZM7.70902 10.5088H6.52251C6.18704 10.5088 6 10.7301 6 11.0664V15.0893C6 15.4479 6.21447 15.647 6.50271 15.647C6.79095 15.647 7.00542 15.4479 7.00542 15.0893V13.9148H7.74924C8.66722 13.9148 9.42479 13.2644 9.42479 12.2186C9.42488 11.1952 8.69394 10.5088 7.70902 10.5088ZM7.68932 12.9711H7.00551V11.4534H7.68932C8.11142 11.4534 8.37996 11.7828 8.37996 12.2127C8.37907 12.6417 8.11142 12.9711 7.68932 12.9711Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default PDF;
