var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var OpenShiftIcon = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_1934:29)' }, { children: _jsx("path", { d: 'M15.9286 11.999H10.3929V9.58264C10.3929 8.65452 11.1004 7.88342 11.9844 7.87405C12.8772 7.86467 13.6071 8.62639 13.6071 9.56155V9.93655C13.6071 10.2483 13.846 10.499 14.1429 10.499H14.8571C15.154 10.499 15.3929 10.2483 15.3929 9.93655V9.56155C15.3929 7.5928 13.8638 5.99202 11.9888 5.99905C10.1138 6.00608 8.60714 7.62795 8.60714 9.5967V11.999H8.07143C7.47991 11.999 7 12.503 7 13.124V16.874C7 17.4951 7.47991 17.999 8.07143 17.999H15.9286C16.5201 17.999 17 17.4951 17 16.874V13.124C17 12.503 16.5201 11.999 15.9286 11.999Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_1934:29' }, { children: _jsx("rect", { width: '10', height: '12', fill: 'white', transform: 'translate(7 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default OpenShiftIcon;
