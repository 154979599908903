var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var FlashOutline = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.3811 2.07552C13.8038 2.24988 14.0544 2.68873 13.9898 3.14138L13.1528 8.99995H19.1998C19.5781 8.99995 19.9239 9.21337 20.0936 9.55144C20.2632 9.88951 20.2276 10.2944 20.0016 10.5976L11.8016 21.5976C11.5283 21.9642 11.0412 22.0988 10.6185 21.9244C10.1958 21.75 9.9452 21.3112 10.0099 20.8585L10.8468 15H4.79981C4.42156 15 4.07569 14.7865 3.90604 14.4485C3.73639 14.1104 3.772 13.7055 3.99806 13.4023L12.1981 2.40229C12.4713 2.0357 12.9585 1.90116 13.3811 2.07552ZM6.79254 13H11.9998C12.2897 13 12.5653 13.1258 12.7553 13.3448C12.9452 13.5638 13.0308 13.8544 12.9898 14.1414L12.5451 17.2538L17.2071 11H11.9998C11.7099 11 11.4343 10.8742 11.2444 10.6552C11.0544 10.4362 10.9689 10.1455 11.0099 9.85853L11.4545 6.74612L6.79254 13Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default FlashOutline;
