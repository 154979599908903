var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Sun = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_3088:174)' }, { children: _jsx("path", { d: 'M12 9.75C10.7602 9.75 9.75 10.7602 9.75 12C9.75 13.2398 10.7602 14.25 12 14.25C13.2398 14.25 14.25 13.2398 14.25 12C14.25 10.7602 13.2398 9.75 12 9.75ZM17.775 11.6367L15.5555 10.5281L16.3406 8.175C16.4461 7.85625 16.1438 7.55391 15.8273 7.66172L13.4742 8.44688L12.3633 6.225C12.2133 5.925 11.7867 5.925 11.6367 6.225L10.5281 8.44453L8.17266 7.65938C7.85391 7.55391 7.55156 7.85625 7.65938 8.17266L8.44453 10.5258L6.225 11.6367C5.925 11.7867 5.925 12.2133 6.225 12.3633L8.44453 13.4719L7.65938 15.8273C7.55391 16.1461 7.85625 16.4484 8.17266 16.3406L10.5258 15.5555L11.6344 17.775C11.7844 18.075 12.2109 18.075 12.3609 17.775L13.4695 15.5555L15.8227 16.3406C16.1414 16.4461 16.4438 16.1437 16.3359 15.8273L15.5508 13.4742L17.7703 12.3656C18.075 12.2133 18.075 11.7867 17.775 11.6367ZM14.1211 14.1211C12.9516 15.2906 11.0484 15.2906 9.87891 14.1211C8.70938 12.9516 8.70938 11.0484 9.87891 9.87891C11.0484 8.70937 12.9516 8.70937 14.1211 9.87891C15.2906 11.0484 15.2906 12.9516 14.1211 14.1211Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_3088:174' }, { children: _jsx("rect", { width: '12', height: '12', fill: 'white', transform: 'translate(6 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Sun;
