var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Approval = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M20.2686 6.73723C20.6591 7.12775 20.6591 7.76092 20.2686 8.15144L12.7126 15.7074C12.5251 15.895 12.2707 16.0004 12.0054 16.0003C11.7402 16.0003 11.4858 15.8949 11.2983 15.7073L7.52132 11.9293C7.13084 11.5388 7.13093 10.9056 7.5215 10.5151C7.91208 10.1247 8.54524 10.1247 8.93572 10.5153L12.0056 13.586L18.8544 6.73723C19.2449 6.3467 19.8781 6.3467 20.2686 6.73723Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12 4C7.58128 4 4 7.58128 4 12C4 16.4187 7.58128 20 12 20C16.4187 20 20 16.4187 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 17.5233 17.5233 22 12 22C6.47672 22 2 17.5233 2 12C2 6.47672 6.47672 2 12 2C13.6172 2 15.1384 2.3933 16.4853 3.0706C16.9787 3.31873 17.1775 3.91986 16.9294 4.41327C16.6813 4.90668 16.0801 5.10552 15.5867 4.8574C14.5036 4.3127 13.2888 4 12 4Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default Approval;
