var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var DirectMessages = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M12.0008 2C10.6876 2 9.38726 2.25866 8.17401 2.7612C6.96075 3.26375 5.85836 4.00035 4.92977 4.92893C3.05441 6.8043 2.00084 9.34784 2.00084 12C1.9921 14.3091 2.79164 16.5485 4.26084 18.33L2.26084 20.33C2.12208 20.4706 2.02809 20.6492 1.99071 20.8432C1.95334 21.0372 1.97426 21.2379 2.05084 21.42C2.1339 21.5999 2.26855 21.7511 2.43769 21.8544C2.60683 21.9577 2.80284 22.0083 3.00084 22H12.0008C14.653 22 17.1965 20.9464 19.0719 19.0711C20.9473 17.1957 22.0008 14.6522 22.0008 12C22.0008 9.34784 20.9473 6.8043 19.0719 4.92893C17.1965 3.05357 14.653 2 12.0008 2ZM12.0008 20H5.41084L6.34084 19.07C6.52709 18.8826 6.63164 18.6292 6.63164 18.365C6.63164 18.1008 6.52709 17.8474 6.34084 17.66C5.03143 16.352 4.21602 14.6305 4.03353 12.7888C3.85105 10.947 4.31279 9.09901 5.34007 7.55952C6.36735 6.02004 7.89663 4.88436 9.66736 4.34597C11.4381 3.80759 13.3407 3.8998 15.0511 4.60691C16.7614 5.31402 18.1737 6.59227 19.0473 8.22389C19.9209 9.85551 20.2017 11.7395 19.842 13.555C19.4822 15.3705 18.5042 17.005 17.0744 18.1802C15.6446 19.3554 13.8516 19.9985 12.0008 20Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default DirectMessages;
