var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import { useEffect, useState } from 'react';
import PhoneNumberInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useWindowSize from '../../utils/useWindowSize';
import useStyles, { styles } from './styles/PhoneInput.styles';
import { FormControl } from '../FormControl/FormControl';
var PhoneInput = function (_a) {
    var _b;
    var id = _a.id, error = _a.error, value = _a.value, required = _a.required, onChange = _a.onChange, ariaLabel = _a.ariaLabel, hiddenLabel = _a.hiddenLabel, inputClassName = _a.inputClassName, dropdownClassName = _a.dropdownClassName, containerClassName = _a.containerClassName, dropdownButtonClassName = _a.dropdownButtonClassName, _c = _a.country, country = _c === void 0 ? 'us' : _c, _d = _a.marginBottom, marginBottom = _d === void 0 ? 0 : _d, _e = _a.disabled, disabled = _e === void 0 ? false : _e, _f = _a.locale, locale = _f === void 0 ? { label: 'Phone', errorMessage: 'Something wrong happened' } : _f, props = __rest(_a, ["id", "error", "value", "required", "onChange", "ariaLabel", "hiddenLabel", "inputClassName", "dropdownClassName", "containerClassName", "dropdownButtonClassName", "country", "marginBottom", "disabled", "locale"]);
    var _g = __read(useState(384), 2), containerWidth = _g[0], setContainerWidth = _g[1];
    var _h = __read(useState(false), 2), isActive = _h[0], setIsActive = _h[1];
    var width = useWindowSize().width;
    var handleFocus = function () {
        setIsActive(true);
    };
    var handeBlur = function () {
        setIsActive(false);
    };
    useEffect(function () {
        var _a;
        if (window) {
            var phoneInputWidth = (_a = document.querySelector('.react-tel-input')) === null || _a === void 0 ? void 0 : _a.clientWidth;
            if (phoneInputWidth)
                setContainerWidth(phoneInputWidth);
            else
                setContainerWidth(384);
        }
    }, [containerWidth, width]);
    var classes = useStyles({ error: !!error });
    var containerClass = cx(classes.container, containerClassName);
    var dropdownButtonClass = cx(classes.dropdownButton, dropdownButtonClassName);
    var dropdownClass = cx(classes.dropdown, dropdownClassName);
    var inputClass = cx(classes.input, inputClassName, (_b = {},
        _b[classes.errorInput] = !!error,
        _b));
    return (_jsx(FormControl, __assign({ id: id, required: required, hiddenLabel: hiddenLabel, active: isActive, label: locale.label, error: error, errorMessage: locale === null || locale === void 0 ? void 0 : locale.errorMessage }, { children: _jsx(PhoneNumberInput, __assign({ containerClass: containerClass, containerStyle: styles.containerStyle, inputClass: inputClass, inputStyle: styles.inputStyle, buttonClass: dropdownButtonClass, buttonStyle: styles.dropdownButtonStyles, dropdownClass: dropdownClass, dropdownStyle: __assign(__assign({}, styles.dropdownStyles), { width: containerWidth }), value: value, disabled: disabled, onBlur: handeBlur, onFocus: handleFocus, onChange: onChange, specialLabel: '', country: country }, props), void 0) }), void 0));
};
export default PhoneInput;
