var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Event = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("g", __assign({ clipPath: 'url(#clip0_29104_331380)' }, { children: _jsx("path", { d: 'M16.375 7.5H15.25V6.375C15.25 6.16875 15.0813 6 14.875 6H14.125C13.9187 6 13.75 6.16875 13.75 6.375V7.5H10.75V6.375C10.75 6.16875 10.5812 6 10.375 6H9.625C9.41875 6 9.25 6.16875 9.25 6.375V7.5H8.125C7.50391 7.5 7 8.00391 7 8.625V9.75H17.5V8.625C17.5 8.00391 16.9961 7.5 16.375 7.5ZM7 16.875C7 17.4961 7.50391 18 8.125 18H16.375C16.9961 18 17.5 17.4961 17.5 16.875V10.5H7V16.875ZM10.143 13.507L11.425 13.3195L11.9992 12.157C12.1023 11.9461 12.4 11.9508 12.5031 12.157L13.0773 13.3195L14.3594 13.507C14.5891 13.5398 14.6805 13.8234 14.5141 13.9875L13.5859 14.8922L13.8062 16.1719C13.8461 16.4039 13.6023 16.575 13.3984 16.4672L12.25 15.8625L11.1039 16.4672C10.9 16.575 10.6562 16.4016 10.6961 16.1719L10.9164 14.8922L9.98828 13.9875C9.81953 13.8234 9.91328 13.5398 10.143 13.507Z', fill: 'white' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'clip0_29104_331380' }, { children: _jsx("rect", { width: '10.5', height: '12', fill: 'white', transform: 'translate(7 6)' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Event;
