var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var DashboardFilled = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M12.3265 2.20966C12.4862 2.06632 12.6974 1.99119 12.9136 2.00082C17.4844 2.13765 21.3044 5.45579 21.9968 9.89061C22.0012 9.91709 22.0012 9.94409 21.9968 9.97057C22.0117 10.1804 21.9408 10.3874 21.7997 10.5458C21.6586 10.7042 21.4591 10.801 21.2451 10.8146L13.5657 11.3211C13.3117 11.3436 13.0598 11.26 12.8719 11.0909C12.684 10.9218 12.5775 10.6828 12.5786 10.4326L12.0624 2.88931V2.76493C12.0718 2.55277 12.1668 2.353 12.3265 2.20966ZM11.7996 13.2937L18.4558 12.8672L18.501 12.885C18.7869 12.8897 19.0591 13.0056 19.2578 13.2072C19.4565 13.4088 19.5654 13.6796 19.5606 13.9601C19.2983 17.7821 16.4961 20.9756 12.6827 21.7983C8.86936 22.6211 4.96017 20.8755 3.08778 17.514C2.53722 16.5458 2.1893 15.4795 2.06445 14.3776C2.01603 14.0511 1.99483 13.7213 2.00106 13.3914C2.01368 9.32721 4.90727 5.81922 8.95605 4.95966C9.44618 4.86792 9.93759 5.11264 10.1514 5.55495C10.2047 5.63521 10.2473 5.72179 10.2782 5.81261C10.3541 6.98419 10.4328 8.14467 10.5112 9.30026C10.5731 10.2129 10.6349 11.1225 10.6948 12.032C10.6917 12.2463 10.7254 12.4596 10.7944 12.6629C10.9569 13.0629 11.3613 13.3167 11.7996 13.2937Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default DashboardFilled;
