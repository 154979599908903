var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var LightbulbFilled = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, props, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M11.998 1.95312C8.13205 1.95312 4.99805 5.08713 4.99805 8.95312C4.99805 10.1911 5.33204 11.3701 5.93604 12.4221C5.94704 12.4421 7.83005 15.4011 7.99805 15.9531H15.998C16.163 15.4041 18.0401 12.4661 18.0291 12.4841C18.6521 11.4211 18.998 10.2111 18.998 8.95312C18.998 5.08713 15.864 1.95312 11.998 1.95312ZM11.998 5.95312C12.55 5.95312 12.998 6.40112 12.998 6.95312C12.998 7.50513 12.55 7.95312 11.998 7.95312C11.446 7.95312 10.998 8.40112 10.998 8.95312C10.998 9.50513 10.55 9.95312 9.99805 9.95312C9.44605 9.95312 8.99805 9.50513 8.99805 8.95312C8.99805 7.29612 10.341 5.95312 11.998 5.95312ZM7.99805 17.9531C7.99805 20.1621 9.78905 21.9531 11.998 21.9531C14.207 21.9531 15.998 20.1621 15.998 17.9531H7.99805Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default LightbulbFilled;
