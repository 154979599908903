var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Bullet = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M7.99984 6.66406C7.73613 6.66406 7.47834 6.74226 7.25908 6.88877C7.03981 7.03528 6.86892 7.24352 6.768 7.48715C6.66708 7.73079 6.64068 7.99887 6.69212 8.25752C6.74357 8.51616 6.87056 8.75373 7.05703 8.9402C7.2435 9.12667 7.48108 9.25366 7.73972 9.30511C7.99836 9.35656 8.26645 9.33015 8.51008 9.22924C8.75372 9.12832 8.96196 8.95742 9.10846 8.73816C9.25497 8.51889 9.33317 8.2611 9.33317 7.9974C9.33317 7.64377 9.19269 7.30464 8.94265 7.05459C8.6926 6.80454 8.35346 6.66406 7.99984 6.66406Z', fill: '#5C627A' }, void 0) }), void 0) }), void 0));
};
export default Bullet;
