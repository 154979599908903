var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var CalendarOutline = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M17 17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V5H16V4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4V5H19C20.6569 5 22 6.34315 22 8V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V8C2 6.34315 3.34315 5 5 5H6V4ZM16 7V8C16 8.55228 16.4477 9 17 9C17.5523 9 18 8.55228 18 8V7H19C19.5523 7 20 7.44772 20 8V11H4V8C4 7.44772 4.44772 7 5 7H6V8C6 8.55228 6.44772 9 7 9C7.55228 9 8 8.55228 8 8V7H16ZM4 13H20V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V13Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default CalendarOutline;
