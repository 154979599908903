var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var AlertWarning = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M13.7676 4.20143C13.0164 2.78246 10.9837 2.78247 10.2325 4.20143L2.89337 18.0642C2.18819 19.3962 3.1538 20.9999 4.66095 20.9999H19.3391C20.8463 20.9999 21.8119 19.3962 21.1067 18.0642L13.7676 4.20143ZM12 8.99994C11.4477 8.99994 11 9.44765 11 9.99994V13.9999C11 14.5522 11.4477 14.9999 12 14.9999C12.5523 14.9999 13 14.5522 13 13.9999V9.99994C13 9.44765 12.5523 8.99994 12 8.99994ZM12 17.9999C12.5523 17.9999 13 17.5522 13 16.9999C13 16.4477 12.5523 15.9999 12 15.9999C11.4477 15.9999 11 16.4477 11 16.9999C11 17.5522 11.4477 17.9999 12 17.9999Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default AlertWarning;
