var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Shadow = function () { return (_jsxs("svg", __assign({ width: '56', height: '24', viewBox: '0 0 56 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("g", __assign({ filter: 'url(#filter0_f_41738_349094)' }, { children: _jsx("ellipse", { cx: '28', cy: '12', rx: '20', ry: '4', fill: '#1A334C', fillOpacity: '0.25' }, void 0) }), void 0), _jsx("g", __assign({ filter: 'url(#filter1_f_41738_349094)' }, { children: _jsx("ellipse", { cx: '28', cy: '12.0004', rx: '10', ry: '1.6', fill: '#1A334C', fillOpacity: '0.25' }, void 0) }), void 0), _jsxs("defs", { children: [_jsxs("filter", __assign({ id: 'filter0_f_41738_349094', x: '0', y: '0', width: '56', height: '24', filterUnits: 'userSpaceOnUse', colorInterpolationFilters: 'sRGB' }, { children: [_jsx("feFlood", { floodOpacity: '0', result: 'BackgroundImageFix' }, void 0), _jsx("feBlend", { mode: 'normal', in: 'SourceGraphic', in2: 'BackgroundImageFix', result: 'shape' }, void 0), _jsx("feGaussianBlur", { stdDeviation: '4', result: 'effect1_foregroundBlur_41738_349094' }, void 0)] }), void 0), _jsxs("filter", __assign({ id: 'filter1_f_41738_349094', x: '14', y: '6.40039', width: '28', height: '11.2002', filterUnits: 'userSpaceOnUse', colorInterpolationFilters: 'sRGB' }, { children: [_jsx("feFlood", { floodOpacity: '0', result: 'BackgroundImageFix' }, void 0), _jsx("feBlend", { mode: 'normal', in: 'SourceGraphic', in2: 'BackgroundImageFix', result: 'shape' }, void 0), _jsx("feGaussianBlur", { stdDeviation: '2', result: 'effect1_foregroundBlur_41738_349094' }, void 0)] }), void 0)] }, void 0)] }), void 0)); };
export default Shadow;
