var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Body, Subtitle } from '../Typography';
import useStyles from './styles/FilesDragAndDrop.styles';
import { Icons } from '../..';
export var FilesDragAndDrop = function (_a) {
    var _b;
    var onUpload = _a.onUpload, count = _a.count, formats = _a.formats, _c = _a.openDialogOnClick, openDialogOnClick = _c === void 0 ? false : _c, onDragEnter = _a.onDragEnter, onDragLeave = _a.onDragLeave, onDrop = _a.onDrop;
    var classes = useStyles();
    var _d = __read(useState(false), 2), dragging = _d[0], setDragging = _d[1];
    var drag = useRef(null);
    var drop = useRef(null);
    var input = useRef(null);
    useEffect(function () {
        var _a, _b, _c, _d;
        (_a = drop.current) === null || _a === void 0 ? void 0 : _a.addEventListener('dragover', handleDragOver);
        (_b = drop.current) === null || _b === void 0 ? void 0 : _b.addEventListener('drop', handleDrop);
        (_c = drop.current) === null || _c === void 0 ? void 0 : _c.addEventListener('dragenter', handleDragEnter);
        (_d = drop.current) === null || _d === void 0 ? void 0 : _d.addEventListener('dragleave', handleDragLeave);
        return function () {
            var _a, _b, _c, _d;
            (_a = drop.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('dragover', handleDragOver);
            (_b = drop.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('drop', handleDrop);
            (_c = drop.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('dragenter', handleDragEnter);
            (_d = drop.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('dragleave', handleDragLeave);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleDragOver = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    var handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        var files = e.dataTransfer ? __spreadArray([], __read(e.dataTransfer.files)) : [];
        onDrop && onDrop(files);
        handleUpload(files);
    };
    var handleDragEnter = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== drag.current) {
            setDragging(true);
            onDragEnter && onDragEnter();
        }
    };
    var handleDragLeave = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target === drag.current) {
            setDragging(false);
            onDragLeave && onDragLeave();
        }
    };
    var handleSelectFiles = function (e) {
        if (e.target.files) {
            var files = __spreadArray([], __read(e.target.files));
            handleUpload(files);
        }
    };
    var handleUpload = function (files) {
        if (count && count < files.length) {
            console.log('error');
            return;
        }
        if (formats
            && files.some(function (file) { return !formats.some(function (format) { return file.name.toLowerCase().endsWith(format.toLowerCase()); }); })) {
            console.log('error');
            return;
        }
        if (files && files.length) {
            onUpload(files);
        }
    };
    var openFileDialog = function () {
        var _a;
        input && ((_a = input.current) === null || _a === void 0 ? void 0 : _a.click());
    };
    return (_jsxs("div", __assign({ ref: drop, style: {
            cursor: openDialogOnClick ? 'pointer' : 'default',
        }, onClick: openDialogOnClick ? openFileDialog : undefined, role: 'button', "aria-hidden": true }, { children: [openDialogOnClick && (_jsx("input", { ref: input, type: 'file', accept: formats ? formats.map(function (format) { return "." + format; }).join(', ') : undefined, multiple: !count || count > 1, onChange: handleSelectFiles, className: classes.input }, void 0)), _jsxs("div", __assign({ className: cx(classes.container, (_b = {},
                    _b[classes.dragging] = dragging,
                    _b)) }, { children: [_jsx(Icons.File, { className: classes.image }, void 0), _jsx(Subtitle, __assign({ className: classes.title }, { children: "Drag & Drop" }), void 0), _jsxs(Body, __assign({ className: classes.description }, { children: ["or", ' ', _jsx("span", { children: "browse" }, void 0), ' ', "to choose a file"] }), void 0)] }), void 0)] }), void 0));
};
