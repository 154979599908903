var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var NotificationFilledBadge = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M18.4391 13.7254L18.7205 14.193C19.092 14.8103 19.2454 15.8679 19.0618 16.553L18.7284 17.7975C18.5432 18.4884 17.8179 18.8833 17.1048 18.6922L4.23221 15.243C3.5281 15.0543 3.08995 14.3416 3.27354 13.6564L3.607 12.4119C3.79212 11.7211 4.45124 10.8881 5.0826 10.5387L5.56009 10.2745L5.89818 9.01273C6.70514 6.00112 9.5975 4.10866 12.7011 4.3333C12.7086 4.29004 12.6967 4.24692 12.7083 4.20345C12.8913 3.5204 13.6206 3.11684 14.329 3.30664C15.0373 3.49644 15.4671 4.21059 15.2841 4.89363C15.2725 4.9371 15.2406 4.96849 15.2254 5.00968C18.0255 6.36694 19.5792 9.47066 18.7719 12.4835L18.4391 13.7254ZM15.8093 19.6761C15.442 21.0472 13.9911 21.8511 12.568 21.4698C11.1449 21.0884 10.2904 19.6668 10.6577 18.2958L15.8093 19.6761Z', fill: '#206AE9' }, void 0), _jsx("circle", { cx: '17', cy: '5', r: '3.75', fill: '#32C356', stroke: 'white', strokeWidth: '1.5' }, void 0)] }), void 0) }), void 0));
};
export default NotificationFilledBadge;
