var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { loginStatusEnum } from '@jobdone/jobdone-shared-library';
import cx from 'classnames';
import { theme } from '../../../utils';
import { Tag } from '../../Tag';
import { DropdownMenu } from '../../DropdownMenu';
import { TableTagActionTypeEnum } from '../../../interfaces';
var useStyles = makeStyles(function () { return ({
    tag: {
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 8,
        padding: '5px 10px',
        height: 34,
    },
    removeAction: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.light,
        },
    },
    addAction: {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    menuItem: {
        '&:not(:last-child)::after': {
            position: 'absolute',
            content: '""',
            bottom: 0,
            left: 0,
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.grey[300],
        },
    },
}); });
export var TableTag = function (_a) {
    var variant = _a.variant, name = _a.name, _b = _a.menuItems, menuItems = _b === void 0 ? [{ value: 'Invite', onClick: function () { return console.log('Action 1'); } }] : _b;
    var classes = useStyles();
    var _c = __read(useState(null), 2), anchorEl = _c[0], setAnchorEl = _c[1];
    var handleClick = function (event) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function (event) {
        event.stopPropagation();
        setAnchorEl(null);
    };
    var renderContent = function () {
        var inviteType = function () {
            if (variant === loginStatusEnum.NOT_INVITED) {
                return (_jsx(Tag, __assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.grey[100], color: theme.palette.grey[700], onClick: handleClick }, { children: name }), void 0));
            }
            if (variant === loginStatusEnum.INVITATION_SENT) {
                return (_jsx(Tag, __assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.main, onClick: handleClick }, { children: name }), void 0));
            }
            if (variant === loginStatusEnum.LOGIN_CONNECTED) {
                return (_jsx(Tag, __assign({ withDropdownIcon: true, className: classes.tag, backgroundColor: '#E7FFF8', color: '#0E956F', onClick: handleClick }, { children: name }), void 0));
            }
            return (_jsx(Tag, __assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.error.light, color: theme.palette.error.main, onClick: handleClick }, { children: name }), void 0));
        };
        return (_jsxs(_Fragment, { children: [inviteType(), _jsx(DropdownMenu, __assign({ anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (_a) {
                        var _b;
                        var value = _a.value, onClick = _a.onClick, actionColor = _a.actionColor;
                        return (_jsx(DropdownMenu.Item, __assign({ onClick: onClick, className: cx(classes.menuItem, (_b = {},
                                _b[classes.removeAction] = actionColor === TableTagActionTypeEnum.REMOVE,
                                _b[classes.addAction] = actionColor === TableTagActionTypeEnum.ADD,
                                _b)) }, { children: value }), String(value)));
                    }) }), void 0)] }, void 0));
    };
    return renderContent();
};
