var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Forward = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '22', height: '22', viewBox: '0 0 12 12', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M7.46961 1.65148C7.32774 1.50556 7.11159 1.46019 6.92303 1.53674C6.73446 1.6133 6.61111 1.7965 6.61111 2.00002V3.81176C6.4624 3.82436 6.2853 3.84309 6.0868 3.87093C5.4866 3.95512 4.67759 4.12439 3.86163 4.46773C3.046 4.81093 2.20316 5.33672 1.56294 6.1449C0.916699 6.96067 0.5 8.03564 0.5 9.42859C0.5 9.62204 0.611588 9.79813 0.786516 9.88072C0.961444 9.96332 1.16832 9.9376 1.3177 9.81468C2.61277 8.74902 4.04152 8.3457 5.15729 8.21068C5.71443 8.14326 6.18893 8.1434 6.52182 8.1601C6.55285 8.16166 6.58263 8.16336 6.61111 8.16516V10C6.61111 10.2035 6.73446 10.3867 6.92303 10.4633C7.11159 10.5398 7.32774 10.4945 7.46961 10.3486L11.3585 6.34856C11.5472 6.15449 11.5472 5.84554 11.3585 5.65148L7.46961 1.65148Z', stroke: '#7F8897', fill: 'none' }, void 0) }), void 0) }), void 0));
};
export default Forward;
