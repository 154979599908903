var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Email = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M2 12C2 6.44772 6.44772 2 12 2C17.5523 2 22 6.44772 22 12V13.5C22 15.4523 20.4523 17 18.5 17C17.2811 17 16.22 16.3967 15.5947 15.4684C14.6842 16.4117 13.4081 17 12 17C9.24771 17 7 14.7523 7 12C7 9.24771 9.24771 7 12 7C14.7523 7 17 9.24771 17 12V13.5C17 14.3477 17.6523 15 18.5 15C19.3477 15 20 14.3477 20 13.5V12C20 7.55228 16.4477 4 12 4C7.55228 4 4 7.55228 4 12C4 16.4477 7.55228 20 12 20C12.9803 20 14.0279 19.8218 14.97 19.397C15.5727 19.0956 16.1293 18.8162 16.5855 18.5425C17.0591 18.2584 17.6733 18.4119 17.9575 18.8855C18.2416 19.3591 18.0881 19.9733 17.6145 20.2575C17.0814 20.5774 16.453 20.8916 15.8682 21.1839L15.8472 21.1944C15.8362 21.1999 15.825 21.2053 15.8138 21.2104C14.56 21.7803 13.2141 22 12 22C6.44772 22 2 17.5523 2 12ZM15 12C15 10.3523 13.6477 9 12 9C10.3523 9 9 10.3523 9 12C9 13.6477 10.3523 15 12 15C13.6477 15 15 13.6477 15 12Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default Email;
