var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var EditAlt = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsx("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { d: 'M5.7 17.401H9.516C9.63445 17.4017 9.75186 17.379 9.86152 17.3343C9.97117 17.2895 10.0709 17.2235 10.155 17.1401L16.383 10.9049L18.939 8.40365C19.0234 8.32001 19.0903 8.2205 19.136 8.11086C19.1817 8.00121 19.2052 7.88361 19.2052 7.76484C19.2052 7.64606 19.1817 7.52846 19.136 7.41882C19.0903 7.30918 19.0234 7.20966 18.939 7.12602L15.123 3.26614C15.0393 3.18181 14.9398 3.11487 14.8301 3.0692C14.7204 3.02352 14.6028 3 14.484 3C14.3652 3 14.2476 3.02352 14.1379 3.0692C14.0282 3.11487 13.9287 3.18181 13.845 3.26614L11.307 5.8124L5.061 12.0476C4.97759 12.1317 4.91159 12.2314 4.86681 12.341C4.82202 12.4506 4.79932 12.568 4.8 12.6864V16.5013C4.8 16.7399 4.89482 16.9688 5.0636 17.1375C5.23239 17.3063 5.46131 17.401 5.7 17.401ZM14.484 5.17359L17.031 7.71985L15.753 8.99748L13.206 6.45122L14.484 5.17359ZM6.6 13.0553L11.937 7.71985L14.484 10.2661L9.147 15.6016H6.6V13.0553ZM20.1 19.2005H3.9C3.66131 19.2005 3.43239 19.2953 3.2636 19.464C3.09482 19.6328 3 19.8616 3 20.1003C3 20.3389 3.09482 20.5677 3.2636 20.7365C3.43239 20.9052 3.66131 21 3.9 21H20.1C20.3387 21 20.5676 20.9052 20.7364 20.7365C20.9052 20.5677 21 20.3389 21 20.1003C21 19.8616 20.9052 19.6328 20.7364 19.464C20.5676 19.2953 20.3387 19.2005 20.1 19.2005Z', fill: 'currentColor' }, void 0) }), void 0) }), void 0));
};
export default EditAlt;
